import { appRouterPaths } from "../../../services/navigation/urls";
import { ClassificationPage } from "../../../pages/ClassificationPage";
import type { AppRouteProps } from "../types";
import { Classification } from "../../../features/Classification";

export const classifiersPageRoute: AppRouteProps = {
  path: appRouterPaths.classifiers,
  element: <ClassificationPage />,
  name: "Классификация тестов",
};

export const classifierPageRoute: AppRouteProps = {
  path: appRouterPaths.classifier,
  element: <Classification />,
  name: "Классификация теста",
};
