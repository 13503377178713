import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAudioTranscription,
  postAudioTranscription,
} from "../../../../services/api/instances/audioTranscriptionApi/methods";
import type { RootState } from "../../../../services/redux/types";

export const postAudioFileAction = createAsyncThunk(
  "audioTranscription/postAudioFile",
  async (file: File) => {
    try {
      return await postAudioTranscription(file as File);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getAudioTranscriptionAction = createAsyncThunk(
  "audioTranscription/getAudioTranscription",
  async (_, ThankApi) => {
    try {
      const { audioTranscriptionInfo } = (ThankApi.getState() as RootState)
        .audioTranscriptionState;
      const signal = ThankApi.signal;

      return await getAudioTranscription(
        audioTranscriptionInfo?.hash as string,
        signal
      );
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);
