import type { StorageObject } from "../../../services/api/instances/storageApi/types";
import type { AssistantInfo } from "../../../services/api/methodsTypes";

export const filteredDialogs = (dialogs: StorageObject[]) => {
  const filterDialogs = dialogs.filter((dialog) => {
    return (
      JSON.parse(dialog.value).thread_id !== "" && dialog.children?.length === 0
    );
  });
  return filterDialogs;
};

export const getThreadId = (dialog: StorageObject) => {
  return JSON.parse(dialog.value).thread_id;
};

export const getFilteredAssistants = (assistants: AssistantInfo[]) => {
  const filteredAssistants = assistants.filter((assistant) => {
    return assistant.metadata?.dialogs === "true";
  });
  return filteredAssistants;
};
