import type { ChangeEvent, FC } from "react";
import { useEffect, useReducer, useState } from "react";
import type { DialogProps, SelectChangeEvent } from "@mui/material";
import styles from "./styles.module.css";
import { INITIAL_STATE_MODAL_CHAT } from "./constant";
import { chatReducer } from "./helpers";
import { SelectBlock } from "../../../shared/components/SelectBlock";
import {
  createTextGeneration,
  getCompModels,
} from "../../../services/api/methods";
import { ModalBasic } from "../../../shared/components/ModalBasic";
import { ClearChatButton } from "../../../features/Modals/ChatModal/components/ClearChatButton";
import { DisplayedMessages } from "./ChatMessageSending/DisplayedMessages";
import { ChatMessageSending } from "./ChatMessageSending";
import { AppSelectOption } from "../../../shared/components/AppSelect/AppSelectOption";
import type { CompModel } from "../../../services/api/methodsTypes";

interface Props extends DialogProps {
  closeModal?: () => void;
}

// todo переписать на redux и все типизировать
export const ChatModal: FC<Props> = ({ closeModal, ...props }) => {
  const [state, dispatchChat] = useReducer(
    chatReducer,
    INITIAL_STATE_MODAL_CHAT
  );
  const [isLoading, setIsLoading] = useState(false);
  const { models, userMessage, messages, selectedModel } = state;

  useEffect(() => {
    if (props.open) {
      getModels();
      getSavedMessages();
    }
  }, [props.open]);

  const getModels = () => {
    getCompModels()
      .then((res) => {
        dispatchChat({
          type: "SET_MODEL_LIST",
          payload: res.models,
        });
        const initialSelectedModel = sessionStorage.getItem("MODEL_SELECTED");
        if (!initialSelectedModel) {
          sessionStorage.setItem("MODEL_SELECTED", res.models[0].model_name);
        }
        dispatchChat({
          type: "SET_SELECTED_MODEL",
          payload: initialSelectedModel || res.models[0].model_name || "",
        });
      })
      .catch(console.log);
  };

  const getSavedMessages = () => {
    const savedMessagesJSON = sessionStorage.getItem("MODAL_CHAT_HISTORY");

    if (savedMessagesJSON) {
      const savedMessages = JSON.parse(savedMessagesJSON);
      dispatchChat({
        type: "SET_MESSAGES",
        payload: savedMessages,
      });
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSelectModel = (event: SelectChangeEvent<unknown>) => {
    sessionStorage.setItem("MODEL_SELECTED", event.target.value as string);
    dispatchChat({
      type: "SET_SELECTED_MODEL",
      payload: event.target.value,
    });
  };

  const handleChangeMessage = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchChat({
      type: "SET_MESSAGE",
      payload: e.target.value,
    });
  };

  const handleClearChat = () => {
    dispatchChat({
      type: "SET_MESSAGES",
      payload: [],
    });
    sessionStorage.setItem("MODAL_CHAT_HISTORY", "[]");
  };

  const handleSendMessage = () => {
    if (!userMessage) {
      return;
    }
    const nextMessages = [...messages, { role: "user", content: userMessage }];
    sessionStorage.setItem("MODAL_CHAT_HISTORY", JSON.stringify(nextMessages));
    dispatchChat({
      type: "SET_MESSAGE",
      payload: "",
    });
    dispatchChat({
      type: "SET_MESSAGES",
      payload: nextMessages,
    });
    setIsLoading(true);
    createTextGeneration({
      model: selectedModel,
      messages: nextMessages,
    }).then((res) => {
      const text = res.choices.map((choice: any) => choice?.message.content);
      const newMessages = [
        ...nextMessages,
        { role: "system", content: text.toString() },
      ];
      dispatchChat({
        type: "SET_MESSAGES",
        payload: newMessages,
      });
      sessionStorage.setItem("MODAL_CHAT_HISTORY", JSON.stringify(newMessages));
      setIsLoading(false);
    });
  };

  return (
    <ModalBasic
      title={"Спросить GPT"}
      hasCloseButton
      closeModal={closeModal}
      hasHeaderPaddingBottom
      className={styles["chat-modal"]}
      {...props}
    >
      <div className={styles["content-header"]}>
        <ClearChatButton clearChat={handleClearChat} className={styles.clear} />
      </div>
      <div className={styles.body}>
        <DisplayedMessages isLoading={isLoading} messages={messages} />
        <ChatMessageSending
          hasAdditionalContent
          handleKeyDown={handleKeyDown}
          sendValue={userMessage}
          onChange={handleChangeMessage}
          sendMessage={handleSendMessage}
          clearChat={handleClearChat}
          additionalContent={
            <SelectBlock
              label={"Выбрать модель"}
              options={models.map((model: CompModel) => (
                <AppSelectOption
                  key={model.model_name}
                  value={model.friendly_name}
                >
                  {model.model_name}
                </AppSelectOption>
              ))}
              onChange={handleSelectModel}
              value={selectedModel}
              itemText={selectedModel}
              className={styles["select-container"]}
            />
          }
          className={styles["send-message-container"]}
        />
      </div>
    </ModalBasic>
  );
};
