import { AppButton } from "../../../shared/components/AppButton";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import { ClassReviewCard } from "./ClassReviewCard";
import { PlusIcon } from "../../../shared/icons/PlusIcon";
import classNames from "classnames";
import styles from "./styles.module.css";
import { ClassCommonSetting } from "./ClassCommonSetting";
import { useCallback } from "react";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import {
  selectClassifierData,
  selectClassifierName,
  selectSelectedClassifier,
} from "../redux/selectors";
import {
  setClassifierDataAction,
  setSelectedClassifierAction,
} from "../redux/slice";
import { debounce } from "throttle-debounce";
import { useClassifier } from "../hooks/use-classifier";
import { generateStringId } from "../../../shared/utils/generateStringId";
import type { PostStorageObject } from "../../../services/api/instances/storageApi/types";
import type {
  ClassPostObjType,
  Label,
} from "../../../services/api/instances/classificatorApi/types";

interface Props {
  leftData: ClassPostObjType;
  updateClassifier: (id: string, postObj: PostStorageObject) => void;
}

export const ClassLeftContainer: React.FC<Props> = ({
  leftData,
  updateClassifier,
}) => {
  const dispatch = useAppDispatch();
  const classifierData = useAppSelector(selectClassifierData);
  const classifierName = useAppSelector(selectClassifierName);
  const selectedClassifier = useAppSelector(selectSelectedClassifier);
  const { onNameChange } = useClassifier();

  const getNextBody = (labels: Label[]): PostStorageObject => {
    const updatedClassifierData = {
      ...classifierData,
      labels,
    };
    dispatch(
      setClassifierDataAction(updatedClassifierData as ClassPostObjType)
    );
    dispatch(
      setSelectedClassifierAction({
        ...selectedClassifier,
        value: JSON.stringify({
          classifier_object: updatedClassifierData,
        }),
      } as any)
    );

    return {
      friendly_name: classifierName,
      value: JSON.stringify({
        classifier_object: updatedClassifierData,
      }),
    };
  };

  const classifierUpdate = useCallback(
    debounce(300, (id: string, postObj: PostStorageObject) => {
      updateClassifier(id, postObj);
    }),
    []
  );

  // useEffect(() => {
  //   dispatch(getCompModelsAction());
  // }, []);

  const handleDeleteVariant = (variantId: string) => {
    const nextLabels = leftData.labels.filter((item) => item.id !== variantId);

    const body = getNextBody(nextLabels);

    classifierUpdate(String(selectedClassifier?.id), body);
  };

  const handleDeleteSample = (variantId: string, sampleId: string) => {
    const nextLabels = leftData.labels.map((item) => {
      if (item.id === variantId) {
        return {
          ...item,
          samples: item.samples.filter((item) => item.id !== sampleId),
        };
      } else {
        return item;
      }
    });

    const body = getNextBody(nextLabels);
    classifierUpdate(String(selectedClassifier?.id), body);
  };

  const handleAddCardVariant = () => {
    const newCard = {
      label: "",
      description: "",
      id: generateStringId(),
      samples: [],
    };

    const body = getNextBody([...leftData.labels, newCard]);

    classifierUpdate(String(selectedClassifier?.id), body);
  };

  const handleAddSample = (variantId: string) => {
    const nextLabels = leftData.labels.map((label) => {
      if (variantId === label.id) {
        return {
          ...label,
          samples: [
            ...label.samples,
            {
              value: "",
              id: generateStringId(),
            },
          ],
        };
      } else {
        return label;
      }
    });

    const body = getNextBody(nextLabels);

    classifierUpdate(String(selectedClassifier?.id), body);
  };

  const handleVariantLabelChange = (value: string, variantId: string) => {
    const nextLabels = leftData.labels.map((item) => {
      if (item.id === variantId) {
        return {
          ...item,
          label: value,
        };
      } else {
        return item;
      }
    });

    const body = getNextBody(nextLabels);

    classifierUpdate(String(selectedClassifier?.id), body);
  };

  const handleVariantDescriptionChange = (value: string, variantId: string) => {
    const nextLabels = leftData.labels.map((item) => {
      if (item.id === variantId) {
        return {
          ...item,
          description: value,
        };
      } else {
        return item;
      }
    });

    const body = getNextBody(nextLabels);

    classifierUpdate(String(selectedClassifier?.id), body);
  };

  const handleSampleInputChange = (
    value: string,
    variantId: string,
    sampleId: string
  ) => {
    const nextLabels = leftData.labels.map((label) => {
      if (variantId === label.id) {
        return {
          ...label,
          samples: label.samples.map((sample) => {
            if (sample.id === sampleId) {
              return {
                ...sample,
                value,
              };
            } else {
              return sample;
            }
          }),
        };
      } else {
        return label;
      }
    });

    const body = getNextBody(nextLabels);

    classifierUpdate(String(selectedClassifier?.id), body);
  };

  return (
    <div className={styles["left-container"]}>
      <ClassCommonSetting onChange={onNameChange} name={classifierName} />
      <AppUnderlinedTitle leftContent="Варианты" className={styles["title"]}>
        <AppButton
          small
          icon={<PlusIcon />}
          buttonType="text"
          className={classNames(styles.button, styles["add-button"])}
          onClick={handleAddCardVariant}
        >
          Добавить
        </AppButton>
      </AppUnderlinedTitle>

      <div className={styles["card-container"]}>
        {leftData?.labels?.map((item: Label, index) => (
          <ClassReviewCard
            key={(item as any).id}
            card={item as Label}
            className={styles.card}
            handleDeleteVariant={handleDeleteVariant}
            handleDeleteSample={handleDeleteSample}
            handleAddSample={handleAddSample}
            handleVariantLabelChange={handleVariantLabelChange}
            handleVariantDescriptionChange={handleVariantDescriptionChange}
            handleSampleInputChange={handleSampleInputChange}
          />
        ))}
      </div>
    </div>
  );
};
