import classNames from "classnames";
import styles from "./styles.module.css";
import { AppChatContainer } from "../../shared/components/AppChatContainer";
import { AppInputBase } from "../../shared/components/AppInputBase";
import type { Message, UploadedFile } from "../../services/api/methodsTypes";
import { AppLoading } from "../../shared/components/AppLoading";
import { AppFileTag } from "../../shared/components/AppFileTag";
import { ClearIcon } from "../../shared/icons/ClearIcon";
import { AppButton } from "../../shared/components/AppButton";
import { AppUnderlinedTitle } from "../../shared/components/AppUnderlinedTitle";

interface Props {
  userMessage: string;
  setUserMessage: (value: string) => void;
  handleSend: () => void;
  handleFileUpload: (e: any) => void;
  isDisabled: boolean;
  messages: Message[];
  attachedFile: UploadedFile | null;
  handleClearChat: () => void;
  setAttachedFile: (value: any) => void;
  isFileLoading: boolean;
  isMessageLoading: boolean;
  customLoadingMessage: string;
  additionalContent?: React.ReactNode | string;
  className?: string;
  hasViewAssistantName?: boolean;
}

export const Chat: React.FC<Props> = ({
  className,
  userMessage,
  setUserMessage,
  handleSend,
  handleFileUpload,
  isDisabled,
  messages,
  attachedFile,
  setAttachedFile,
  isFileLoading,
  isMessageLoading,
  customLoadingMessage,
  handleClearChat,
  additionalContent,
  hasViewAssistantName,
}) => {
  const handleSendClick = () => {
    if (!isDisabled && !isFileLoading && userMessage) handleSend();
  };

  const handleSendKeyDown = (event: any) => {
    if (event.key === "Enter" && event.ctrlKey) {
      event.preventDefault();
      handleSendClick();
    }
  };

  const handleFileClick = (e: any) => {
    handleFileUpload(e);
  };

  return (
    <div className={classNames(styles.layout, className)}>
      <AppUnderlinedTitle
        leftContent={additionalContent}
        className={styles.title}
      >
        <AppButton
          className={classNames(styles.clear, {
            [styles.disabled]: isDisabled,
          })}
          small
          onClick={handleClearChat}
          buttonType="text"
          icon={<ClearIcon />}
          disabled={isDisabled}
        >
          Очистить
        </AppButton>
      </AppUnderlinedTitle>
      <AppChatContainer
        chatClassName={styles.chat}
        messages={messages}
        isMessageLoading={isMessageLoading}
        customLoadingMessage={customLoadingMessage}
        hasViewAssistantName={hasViewAssistantName}
      >
        <>
          <div className={styles["file-container"]}>
            {isFileLoading && <AppLoading small className={styles.loading} />}
            {attachedFile && (
              <AppFileTag
                fileName={attachedFile.filename}
                onDelete={() => setAttachedFile(null)}
              />
            )}
          </div>
          <div className={styles["assist-bottom-container"]}>
            <AppInputBase
              small
              inputValue={userMessage}
              setInputValue={setUserMessage}
              handleSendKeyDown={handleSendKeyDown}
              className={styles.input}
              minRows={4}
              maxRows={30}
              multiline
              fileButton
              sendButton
              onFileClick={handleFileClick}
              onSend={handleSendClick}
              isSendDisabled={isDisabled || isFileLoading}
              placeholder="Введите сообщение"
            />
          </div>
        </>
      </AppChatContainer>
    </div>
  );
};
