import { useEffect, useState } from "react";
import {
  selectDialogName,
  selectDialogs,
  selectIsDialogsLoading,
  selectSelectedDialog,
} from "../../../features/Dialog/redux/selectors";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import {
  createDialogAction,
  createThreadAction,
  deleteDialogAction,
  getDialogMessagesAction,
  getDialogsAction,
} from "../../../features/Dialog/redux/actions";
import {
  clearMessagesAction,
  setDialogNameAction,
  setDialogsAction,
  setSelectedDialogAction,
} from "../../../features/Dialog/redux/slice";
import { useNavigate } from "react-router-dom";
import { getDialogPagePath } from "../../../services/navigation/urls";
import type { StorageObject } from "../../../services/api/instances/storageApi/types";

export const useDialogsPage = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector(selectDialogName);
  const selectedDialog = useAppSelector(selectSelectedDialog);
  const dialogsList = useAppSelector(selectDialogs);
  const isDialogsLoading = useAppSelector(selectIsDialogsLoading);
  const navigate = useNavigate();
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    if (!dialogsList) return;
    dispatch(getDialogsAction())
      .unwrap()
      .then((response) => {
        const activeDialog = response.objects[0];
        dispatch(setSelectedDialogAction(activeDialog as StorageObject));
        dispatch(setDialogNameAction(activeDialog?.friendly_name as string));
        dispatch(clearMessagesAction());
        if (!activeDialog) return;
        navigate(getDialogPagePath(String(activeDialog.id)));
      });
  }, []);

  const addDialog = () => {
    setCounter(counter + 1);
    dispatch(createThreadAction())
      .unwrap()
      .then((res) => {
        const dialog = {
          friendly_name: !dialogName ? `Диалог ${counter}` : dialogName,
          value: JSON.stringify({
            thread_id: res.id,
          }),
        };
        dispatch(createDialogAction(dialog as StorageObject))
          .unwrap()
          .then((response) => {
            const newDialog = { ...response };
            const newDialogs = [...dialogsList, newDialog];
            dispatch(setDialogsAction(newDialogs));
            dispatch(clearMessagesAction());
            dispatch(setDialogNameAction(response.friendly_name));
            dispatch(getDialogMessagesAction());
            navigate(getDialogPagePath(String(response.id)));
          });
      });
  };

  const handleSelectDialog = (id: string) => {
    const dialog = dialogsList.find((thread) => {
      return String(thread.id) === String(id);
    });
    dispatch(clearMessagesAction());
    dispatch(setSelectedDialogAction(dialog as StorageObject));
    dispatch(setDialogNameAction(dialog?.friendly_name as string));
    dispatch(getDialogMessagesAction());
    navigate(getDialogPagePath(id));
  };

  const handleDeletedThread = (id: string, name: string) => {
    dispatch(deleteDialogAction({ id, navigate }));
  };

  return {
    selectedDialog,
    dialogsList,
    isDialogsLoading,
    addDialog,
    handleSelectDialog,
    handleDeletedThread,
  };
};
