import { AppSidebar } from "../../common/Sidebar";
import styles from "./styles.module.css";
import { useDialogsPage } from "./hook/use-dialogs-page";
import { Outlet } from "react-router-dom";
import type { StorageObject } from "../../services/api/instances/storageApi/types";

export const DialogsPage = () => {
  const {
    selectedDialog,
    dialogsList,
    isDialogsLoading,
    addDialog,
    handleSelectDialog,
    handleDeletedThread,
  } = useDialogsPage();
  return (
    <div className={styles["page-layout"]}>
      <AppSidebar
        onAdd={addDialog}
        defaultName="Диалог"
        menuItems={dialogsList as StorageObject[]}
        selectedId={selectedDialog?.id}
        onSelect={handleSelectDialog}
        onDelete={handleDeletedThread}
        isLoading={isDialogsLoading}
        isObjectsStorage
      />
      {selectedDialog && <Outlet />}
    </div>
  );
};
