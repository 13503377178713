import { AppIconButton } from "../../../../../shared/components/AppIconButton";
import { AppInputBase } from "../../../../../shared/components/AppInputBase";
import { TrashIcon } from "../../../../../shared/icons/TrashIcon";
import classNames from "classnames";
import styles from "./styles.module.css";
import type { ChangeEvent } from "react";

interface Props {
  className?: string;
  inputValue: string;
  inputId: string;
  onDeleteInput: (inputId: string) => void;
  onInputChange: (value: string, index: string) => void;
}

export const ClassInputContainer: React.FC<Props> = ({
  className,
  inputValue,
  inputId,
  onDeleteInput,
  onInputChange,
}) => {
  return (
    <div className={styles["input-container"]}>
      <AppInputBase
        className={classNames(styles.input, className)}
        small
        rows={undefined}
        placeholder="Пример"
        onChange={(e) => onInputChange(e.target.value, inputId)}
        value={inputValue}
      />
      <AppIconButton onClick={() => onDeleteInput(inputId)}>
        <TrashIcon />
      </AppIconButton>
    </div>
  );
};
