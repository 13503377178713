import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import type { DialogsState } from "./types";
import {
  createDialogAction,
  deleteDialogAction,
  getDialogFunctionsAction,
  getDialogMessagesAction,
  getDialogsAction,
  getDialogsAssistantAction,
  postDialogMessageAction,
  saveUpdatedAssistantAction,
  updateMessagesAction,
} from "./actions";
import type { AssistantInfo } from "../../../services/api/methodsTypes";
import { filteredDialogs } from "../helpers";

export function getDialogAssistantsReducerBuilder(
  builder: ActionReducerMapBuilder<DialogsState>
) {
  builder.addCase(getDialogsAssistantAction.pending, (state) => {
    state.isDialogsLoading = true;
  });
  builder.addCase(getDialogsAssistantAction.fulfilled, (state, action) => {
    state.assistants = action.payload.data.filter(
      (assistant: AssistantInfo) => assistant.metadata?.dialogs === "true"
    );
    state.isDialogsLoading = false;
  });
  builder.addCase(getDialogsAssistantAction.rejected, (state) => {
    state.isDialogsLoading = false;
  });
}

export function getAllDialogsReducerBuilder(
  builder: ActionReducerMapBuilder<DialogsState>
) {
  builder.addCase(getDialogsAction.pending, (state) => {
    state.isDialogsLoading = true;
  });
  builder.addCase(getDialogsAction.fulfilled, (state, action) => {
    state.isDialogsLoading = false;
    state.dialogs = filteredDialogs(action.payload.objects);
  });
  builder.addCase(getDialogsAction.rejected, (state) => {
    state.isDialogsLoading = false;
  });
}

export const createDialogReducerBuilder = (
  builder: ActionReducerMapBuilder<DialogsState>
) => {
  builder.addCase(createDialogAction.fulfilled, (state, action) => {
    state.dialogs = [...state.dialogs, action.payload];
    state.selectedDialog = action.payload;
  });
};

export function deleteDialogReducerBuilder(
  builder: ActionReducerMapBuilder<DialogsState>
) {
  builder.addCase(deleteDialogAction.pending, (state) => {
    state.isDialogsLoading = true;
  });
  builder.addCase(deleteDialogAction.fulfilled, (state) => {
    state.isDialogsLoading = false;
  });
  builder.addCase(deleteDialogAction.rejected, (state) => {
    state.isDialogsLoading = false;
  });
}

export function getDialogFunctionsReducerBuilder(
  builder: ActionReducerMapBuilder<DialogsState>
) {
  builder.addCase(getDialogFunctionsAction.pending, (state) => {
    state.isFunctionsLoading = true;
  });
  builder.addCase(getDialogFunctionsAction.fulfilled, (state, action) => {
    state.functions = action.payload;
    state.isFunctionsLoading = false;
  });
  builder.addCase(getDialogFunctionsAction.rejected, (state) => {
    state.isFunctionsLoading = false;
  });
}

export function saveUpdatedAssistantReducerBuilder(
  builder: ActionReducerMapBuilder<DialogsState>
) {
  builder.addCase(saveUpdatedAssistantAction.fulfilled, (state, action) => {
    state.selectedAssistant = {
      ...state.selectedAssistant!,
      response_format: action.payload.response_format,
    };
    state.assistants = state.assistants.map((assistant: AssistantInfo) => {
      if (assistant.id === action.payload.id) {
        return {
          ...assistant,
          response_format: action.payload.response_format,
        };
      } else {
        return assistant;
      }
    });
  });
}

export function postDialogMessageReducerBuilder(
  builder: ActionReducerMapBuilder<DialogsState>
) {
  builder.addCase(postDialogMessageAction.pending, (state) => {
    state.isMessageLoading = true;
  });
  builder.addCase(postDialogMessageAction.fulfilled, (state, action) => {
    state.messages = state.messages.map((item, index) => {
      if (state.messages.length - 1 === index) {
        return action.payload;
      } else {
        return item;
      }
    });
    state.isMessageLoading = false;
  });
  builder.addCase(postDialogMessageAction.rejected, (state) => {
    state.isMessageLoading = false;
  });
}

export function getDialogMessagesReducerBuilder(
  builder: ActionReducerMapBuilder<DialogsState>
) {
  builder.addCase(getDialogMessagesAction.pending, (state) => {
    state.isMessageLoading = true;
    state.isSendDisabled = true;
  });
  builder.addCase(getDialogMessagesAction.fulfilled, (state, action) => {
    state.messages = action.payload.data;
    state.isMessageLoading = false;
    state.isSendDisabled = false;
  });
  builder.addCase(getDialogMessagesAction.rejected, (state) => {
    state.isMessageLoading = false;
    state.isSendDisabled = false;
  });
}

export function updateMessagesReducerBuilder(
  builder: ActionReducerMapBuilder<DialogsState>
) {
  builder.addCase(updateMessagesAction.pending, (state) => {
    state.isMessageLoading = true;
  });
  builder.addCase(updateMessagesAction.fulfilled, (state, action) => {
    state.messages = action.payload.data;
    state.isMessageLoading = false;
  });
  builder.addCase(updateMessagesAction.rejected, (state) => {
    state.isMessageLoading = false;
  });
}
