import { useEffect, useState, type ChangeEvent, type FC } from "react";
import styles from "./styles.module.css";
import { DialogAssistantSelection } from "../DialogAssistantSelection";
import type { SelectChangeEvent } from "@mui/material";
import type { AssistantInfo } from "../../../../services/api/methodsTypes";
import { AppInput } from "../../../../shared/components/AppInput";
import { useAppDispatch } from "../../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../../services/redux/hooks/use-selector";
import {
  selectDialogName,
  selectDialogs,
  selectSelectedDialog,
} from "../../redux/selectors";
import { setDialogNameAction, setDialogsAction } from "../../redux/slice";
import { updateDialogAction } from "../../redux/actions";
import type { StorageObject } from "../../../../services/api/instances/storageApi/types";

interface Props {
  selectedAssistant: AssistantInfo;
  assistants: AssistantInfo[];
  handleSelectAssistant: (event: SelectChangeEvent<unknown>) => void;
}

export const DialogsHeader: FC<Props> = ({
  selectedAssistant,
  assistants,
  handleSelectAssistant,
}) => {
  const dispatch = useAppDispatch();
  const selectedDialog = useAppSelector(selectSelectedDialog);
  const dialogs = useAppSelector(selectDialogs);
  const dialogName = useAppSelector(selectDialogName);
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    if (typing && selectedDialog) {
      const timer = setTimeout(() => {
        setTyping(false);
        const updatedDialog = {
          friendly_name: dialogName,
          value: selectedDialog.value,
        };
        const id = String(selectedDialog?.id);
        const updatedDialogList = dialogs.map((dialog: StorageObject) => {
          if (String(dialog.id) === String(selectedDialog?.id)) {
            return {
              ...dialog,
              friendly_name: dialogName,
            };
          }
          return dialog;
        });
        dispatch(setDialogsAction(updatedDialogList));
        dispatch(updateDialogAction({ id, postObj: updatedDialog }));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [typing, dialogName]);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTyping(true);
    const updatedDialogList = dialogs.map((dialog: StorageObject) => {
      if (String(dialog.id) === String(selectedDialog?.id)) {
        return {
          ...dialog,
          friendly_name: event.target.value,
        };
      }
      return dialog;
    });
    dispatch(setDialogsAction(updatedDialogList));
    dispatch(setDialogNameAction(event.target.value));
  };

  return (
    <div className={styles["header-container"]}>
      <AppInput
        value={dialogName}
        onChange={onNameChange}
        className={styles.input}
        placeholder="Название диалога"
      />
      <DialogAssistantSelection
        assistants={assistants}
        itemText={selectedAssistant?.name as string}
        value={selectedAssistant?.name}
        onSelectAssistant={handleSelectAssistant}
      />
    </div>
  );
};
