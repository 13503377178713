import { useAppDispatch } from "../../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../../services/redux/hooks/use-selector";
import { selectAudioTranscriptionInfo } from "../redux/selectors";
import type { AudioTranscriptionInfo } from "../types";
import {
  getAudioTranscriptionAction,
  postAudioFileAction,
} from "../redux/actions";
import { getNumber, getProcessingTime } from "../helpers";
import type {
  AudioTranscriptionGetResponse,
  AudioTranscriptionPostResponse,
} from "../../../../services/api/instances/audioTranscriptionApi/types";
import {
  setAudioTranscriptionInfo,
  setTranscriptionModeAction,
} from "../redux/slice";

export const useAudioTranscription = () => {
  const dispatch = useAppDispatch();
  const audioTranscription = useAppSelector(selectAudioTranscriptionInfo);

  const uploadAudioFile = (file: File) => {
    let errorOccurred = false;
    dispatch(postAudioFileAction(file))
      .unwrap()
      .then((res) => {
        const processingTime = getProcessingTime(
          file.size,
          res.estimate_duration
        );
        const loadingInfo =
          "Файл загружен. Ориентировочное время обработки файла составляет:";
        dispatch(
          setAudioTranscriptionInfo({
            hash: res.hash,
            progress: 0,
            status: "ready",
            text: `${loadingInfo} ${processingTime}`,
          })
        );
      })
      .catch(() => {
        errorOccurred = true;
        dispatch(setTranscriptionModeAction("error"));
      })
      .finally(() => {
        if (errorOccurred) {
          dispatch(setTranscriptionModeAction("error"));
        } else {
          dispatch(setTranscriptionModeAction("ready"));
        }
      });
  };

  const getProcessingResult = (signal: AbortSignal) => {
    const interval = setTimeout(function tick() {
      if (signal.aborted) {
        clearTimeout(interval);
        return;
      }
      dispatch(getAudioTranscriptionAction())
        .unwrap()
        .then((res) => {
          const { status, progress, text } = res;
          switch (status) {
            case "processing": {
              dispatch(
                setAudioTranscriptionInfo({
                  ...(audioTranscription as AudioTranscriptionInfo),
                  progress: getNumber(progress),
                  status: status,
                })
              );
              dispatch(setTranscriptionModeAction("processing"));
              setTimeout(tick, 3000);
              return;
            }
            case "completed": {
              dispatch(
                setAudioTranscriptionInfo({
                  ...(audioTranscription as AudioTranscriptionInfo),
                  progress: getNumber(progress),
                  text: text,
                  status: status,
                })
              );
              const timer = setTimeout(() => {
                dispatch(setTranscriptionModeAction("success"));
                clearTimeout(interval);
                clearTimeout(timer);
              }, 500);
              return;
            }
            case "failed": {
              dispatch(setTranscriptionModeAction("failed"));
              dispatch(
                setAudioTranscriptionInfo({
                  ...(audioTranscription as AudioTranscriptionInfo),
                  status: status,
                })
              );
              clearTimeout(interval);
              return;
            }
            default:
              return;
          }
        })
        .catch(() => {
          dispatch(setTranscriptionModeAction("failed"));
          clearTimeout(interval);
        });
    });
  };

  return {
    uploadAudioFile,
    getProcessingResult,
  };
};
