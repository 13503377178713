import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../services/redux/types";

export const selectAudioTranscriptionState = (state: RootState) =>
  state.audioTranscriptionState;

export const selectTranscriptionMode = createSelector(
  selectAudioTranscriptionState,
  (state) => {
    return state.transcriptionMode;
  }
);

export const selectAudioFile = createSelector(
  selectAudioTranscriptionState,
  (state) => {
    return state.audioFileName;
  }
);

export const selectAudioTranscriptionInfo = createSelector(
  selectAudioTranscriptionState,
  (state) => {
    return state.audioTranscriptionInfo;
  }
);

export const selectIsFileUpload = createSelector(
  selectAudioTranscriptionState,
  (state) => {
    return state.isFileUpload;
  }
);

export const selectIsFileTranscription = createSelector(
  selectAudioTranscriptionState,
  (state) => {
    return state.isFileTranscription;
  }
);
