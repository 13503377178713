import type { AxiosError } from "axios";
import { appClassificatorToolsApi } from "./instance";
import type { ClassPostObjType, PredictionObject } from "./types";
import { multiUserApi } from "../../../../shared/utils/multiUserFunctions";
import { showError } from "../../../../shared/utils/showError/showError";

export const getClasses = (
  postObj: ClassPostObjType
): Promise<PredictionObject> => {
  return appClassificatorToolsApi
    .post("/nlp_class", postObj, {
      withCredentials: false,
      headers: {
        "X-Keycloak-Token": multiUserApi.getUserKey("access_token"),
      },
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getClasses error: " + error.message);
      console.log(error);
      throw error;
    });
};
