import squares from "../../shared/assets/img/squares.png";
import logo from "../../shared/assets/img/logo_light.png";
import styles from "./styles.module.css";
import { AppHeader } from "../../common/Header";

export const NotFoundPage = () => {
  return (
    <>
      <AppHeader />
      <div className={styles["page-layout"]}>
        <div className={styles.title}>
          К пользователю не подключён ни один модуль, пожалуйста, напишите
          администратору
        </div>
        <img className={styles["background-squares"]} src={squares} alt="" />
      </div>
    </>
  );
};
