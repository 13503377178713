import { useEffect } from "react";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import { getFilteredAssistants } from "../helpers";
import {
  getDialogFunctionsAction,
  getDialogMessagesAction,
  getDialogsAssistantAction,
} from "../redux/actions";
import {
  selectAssistants,
  selectDialogFunctions,
  selectSelectedAssistant,
} from "../redux/selectors";
import {
  setDialogAssistantsAction,
  setSelectedAssistantAction,
} from "../redux/slice";

export const useDialogs = () => {
  const dispatch = useAppDispatch();
  const assistants = useAppSelector(selectAssistants);
  const selectedAssistant = useAppSelector(selectSelectedAssistant);
  const dialogFunction = useAppSelector(selectDialogFunctions);

  useEffect(() => {
    dispatch(getDialogsAssistantAction())
      .unwrap()
      .then((res) => {
        const filteredAssistants = getFilteredAssistants(res.data);
        const currentAssistant = filteredAssistants.find(
          (item) => item.id === selectedAssistant?.id
        );
        const chosenAssistant = currentAssistant || filteredAssistants[0];
        if (chosenAssistant) {
          dispatch(setSelectedAssistantAction(chosenAssistant));
          dispatch(getDialogFunctionsAction(chosenAssistant?.id as string));
        }
        dispatch(setDialogAssistantsAction(filteredAssistants));
      })
      .finally(() => {
        dispatch(getDialogMessagesAction());
      });
  }, []);

  return {
    assistants,
    selectedAssistant,
    dialogFunction,
  };
};
