let audioTranscriptionToken: string | undefined;
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  audioTranscriptionToken = process.env.REACT_APP_AUDIO_TRANSCRIPTION_TOKEN;
} else {
  audioTranscriptionToken =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_AUDIO_TRANSCRIPTION_TOKEN;
}

export { audioTranscriptionToken };
