import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../services/redux/types";

export const selectDialogsState = (state: RootState) => state.dialogsState;

export const selectAssistants = createSelector(selectDialogsState, (state) => {
  return state.assistants;
});

export const selectSelectedAssistant = createSelector(
  selectDialogsState,
  (state) => {
    return state.selectedAssistant;
  }
);

export const selectDialogs = createSelector(selectDialogsState, (state) => {
  return state.dialogs;
});

export const selectSelectedDialog = createSelector(
  selectDialogsState,
  (state) => {
    return state.selectedDialog;
  }
);

export const selectMessages = createSelector(selectDialogsState, (state) => {
  return state.messages;
});

export const selectDialogFunctions = createSelector(
  selectDialogsState,
  (state) => {
    return state.functions;
  }
);

export const selectIsSendDisabled = createSelector(
  selectDialogsState,
  (state) => {
    return state.isSendDisabled;
  }
);

export const selectDialogName = createSelector(selectDialogsState, (state) => {
  return state.dialogName;
});

export const selectIsDialogsLoading = createSelector(
  selectDialogsState,
  (state) => {
    return state.isDialogsLoading;
  }
);
