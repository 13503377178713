import { useRef, useState } from "react";
import { AppButton } from "../../../shared/components/AppButton";
import { AppInputBase } from "../../../shared/components/AppInputBase";
import { AppLabel } from "../../../shared/components/AppLabel";
import { AppSelect } from "../../../shared/components/AppSelect";
import { AppSelectOption } from "../../../shared/components/AppSelect/AppSelectOption";
import { AppSwitch } from "../../../shared/components/AppSwitch";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import { PlusIcon } from "../../../shared/icons/PlusIcon";
import type { TableDataRow } from "./ChatbotTable";
import { ChatbotTable } from "./ChatbotTable";
import { ChatbotUser } from "./ChatbotUser";
import { AppLoading } from "../../../shared/components/AppLoading";
import type {
  AssistantInfoWithPlaceholder,
  ChatbotWithChats,
  UserListType,
  SelectedUserListType,
} from "../../../pages/ChatbotPage";
import { getBotChats, updateBot } from "../../../services/api/methods";
import {
  getFilteredBotToPost,
  getFilteredUserList,
} from "../../../shared/utils/chatbotFunctions";
import type { SelectChangeEvent } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";
import { useProfile } from "../../../services/profile";
import type { MenuItem } from "../../../common/Sidebar/types";

interface Props {
  chatbotList: ChatbotWithChats[];
  setChatbotList: React.Dispatch<
    React.SetStateAction<ChatbotWithChats[] | null>
  >;
  selectedBot: ChatbotWithChats;
  handleChangeChatbotList: (selectedBot: ChatbotWithChats) => void;
  assistantList: AssistantInfoWithPlaceholder[] | null;
  secureBotList: string[];
  handleChangeApi: (oldToken: string) => void;
  selectedUserList: UserListType | null;
  handleChangeUserlist: (
    functionType: "add" | "change" | "delete",
    ref: string,
    listType: "white_list" | "black_list",
    user?: SelectedUserListType
  ) => UserListType | undefined;
  setChangeAssistantDialogOpen: (isOpen: boolean) => void;
  setChangingChatId: React.Dispatch<React.SetStateAction<string | null>>;
  isChatsLoading: boolean;
}

type CheckType =
  | "log_message"
  | "stream"
  | "security_check"
  | "check_validation";

type InputType = "start_message" | "secure_chat_id";

export const ChatbotMain: React.FC<Props> = ({
  chatbotList,
  setChatbotList,
  selectedBot,
  handleChangeChatbotList,
  assistantList,
  secureBotList,
  handleChangeApi,
  selectedUserList,
  handleChangeUserlist,
  setChangeAssistantDialogOpen,
  setChangingChatId,
  isChatsLoading,
}) => {
  const { userInfo } = useProfile();
  const [localIsChatsLoading, setLocalIsChatsLoading] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Добавление селекта с пустым пользователем
  const handleAddUser = (type: "white_list" | "black_list") => {
    handleChangeUserlist("add", selectedBot.ref, type);
  };

  // Изменение селекта пользователя
  const handleUserChange = (
    event: SelectChangeEvent<unknown>,
    selectId: string,
    type: "white_list" | "black_list"
  ) => {
    const changedUserId = event.target.value as string;
    const newUserName =
      selectedUserList?.userOptions.find((user) => user.id === changedUserId)
        ?.name || "";
    const localNewUser = {
      selectId,
      id: changedUserId,
      name: newUserName,
    };

    const newBotInfo = handleChangeUserlist(
      "change",
      selectedBot.ref,
      type,
      localNewUser
    );
    if (!newBotInfo) return;
    const filteredUserList = getFilteredUserList(newBotInfo, type);
    const newBot = {
      ...selectedBot,
      [type]: filteredUserList,
    };
    handleChangeChatbotList(newBot);
    updateBot(selectedBot.ref, getFilteredBotToPost(newBot));
  };

  // Удаление селекта пользователя
  const handleUserDelete = (
    selectId: string,
    type: "white_list" | "black_list"
  ) => {
    const localListType =
      type === "white_list"
        ? "selectedWhitelistUsers"
        : "selectedBlacklistUsers";
    const newUser = selectedUserList?.[localListType].find(
      (user) => user.selectId === selectId
    );

    const newBotInfo = handleChangeUserlist(
      "delete",
      selectedBot.ref,
      type,
      newUser
    );
    if (!newBotInfo) return;
    const filteredUserList = getFilteredUserList(newBotInfo, type);
    const newBot = {
      ...selectedBot,
      [type]: filteredUserList,
    };
    handleChangeChatbotList(newBot);
    updateBot(selectedBot.ref, getFilteredBotToPost(newBot));
  };

  const handleAssistantChange = (event: any) => {
    const oldItem = selectedBot;
    oldItem.asst_name = event.target.value;
    handleChangeChatbotList(oldItem);
    updateBot(selectedBot.ref, getFilteredBotToPost(oldItem));
  };

  const handleBotAdminChange = (event: any) => {
    const oldItem = { ...selectedBot };
    oldItem.secure_bot_name = event.target.value;
    oldItem.secure_chat_id = "emptyId";

    const newChatbotList = chatbotList.map((bot) =>
      bot.ref === selectedBot.ref ? oldItem : bot
    );

    // Если мы меняем секБота на того, у которого еще нет чатов, то мы фетчим чаты
    // и сохраняем их в общий стейт
    const localSecureBot = newChatbotList.find(
      (bot) => bot.bot_name === event.target.value
    );
    if (
      event.target.value !== "emptyId" &&
      localSecureBot &&
      !localSecureBot.chats
    ) {
      setLocalIsChatsLoading(true);
      getBotChats(localSecureBot.ref)
        .then((chats) => {
          const newSecureBot = { ...localSecureBot };
          const newChats = chats.chats;
          newSecureBot.chats = newChats;

          const updatedChatbotList = newChatbotList.map((bot) =>
            bot.ref === newSecureBot.ref ? newSecureBot : bot
          );

          setChatbotList(updatedChatbotList);
        })
        .finally(() => {
          setLocalIsChatsLoading(false);
        });
    }
    // Если у бота уже есть чаты, то просто обновляем нынешнего бота
    else {
      setChatbotList(newChatbotList);
    }

    // Апдейтим только выбранного бота, т.к. секБоту добавляем только чаты
    updateBot(selectedBot.ref, getFilteredBotToPost(oldItem));
  };

  const handleSecureChatChange = (event: any) => {
    const oldItem = { ...selectedBot };
    oldItem.secure_chat_id = event.target.value;
    handleChangeChatbotList(oldItem);
    updateBot(selectedBot.ref, getFilteredBotToPost(oldItem));
  };

  const getSecureChatList = (): MenuItem[] => {
    const emptyItem: MenuItem = {
      id: "emptyId",
      name: "Без чата",
    };
    if (!selectedBot.secure_bot_name) {
      console.log("err1");
      return [emptyItem];
    }

    const secureBot = chatbotList.find(
      (bot) => bot.bot_name === selectedBot.secure_bot_name
    );
    // console.log("secureBot", secureBot);
    if (!secureBot || !secureBot.chats) {
      // console.log("err2");
      return [emptyItem];
    }

    const localChatList = secureBot.chats.map((chat) => ({
      id: chat.chat_id,
      name: chat.chat_name,
    }));
    localChatList.unshift(emptyItem);
    return localChatList;
  };

  const getSecureChatName = (): string => {
    const localSecureChatList = getSecureChatList();
    const chatName =
      localSecureChatList.find((item) => item.id === selectedBot.secure_chat_id)
        ?.name || "Без чата";

    return chatName;
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: InputType
  ) => {
    const oldItem = selectedBot;
    oldItem[type] = event.target.value;
    handleChangeChatbotList(oldItem);

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      updateBot(selectedBot.ref, getFilteredBotToPost(oldItem));
    }, 1500);
  };

  const handleSwitchChange = (event: any, type: CheckType) => {
    const oldItem = selectedBot;
    oldItem[type] = event.target.checked;
    handleChangeChatbotList(oldItem);
    updateBot(selectedBot.ref, getFilteredBotToPost(oldItem));
  };

  const getTableRowData = (): TableDataRow[] => {
    if (!selectedBot.chats || !selectedBot.chats.length) return [];
    const localChats = selectedBot.chats;
    const returnedChats: TableDataRow[] = localChats.map((chat) => {
      return {
        chatId: chat.chat_id,
        chatName: chat.chat_name,
        assistantId: chat.asst_name,
      };
    });

    return returnedChats;
  };

  return (
    <div className={styles.container}>
      <div className={styles["block-container"]}>
        <AppUnderlinedTitle className={styles.title} leftContent="Основное" />
        <p className={styles["bot-name"]}>{selectedBot.bot_name}</p>
        <div className={styles["api-container"]}>
          <p className={styles["bot-api"]}>
            <span className={styles["bot-api-header"]}>Telegram bot API: </span>
            <span className={styles["bot-api-content"]}>
              {selectedBot.bot_api}
            </span>
          </p>
          <AppButton
            small
            buttonType="text"
            className={styles["change-button"]}
            onClick={() => handleChangeApi(selectedBot.bot_api)}
          >
            Сменить
          </AppButton>
        </div>
        <AppInputBase
          className={styles["input-base"]}
          value={selectedBot.start_message}
          onChange={(e) => handleInputChange(e, "start_message")}
          rows={4}
          placeholder="Приветственное сообщение"
        />
        <AppLabel className={styles.label}>Ассистент по умолчанию</AppLabel>
        {assistantList ? (
          <AppSelect
            className={styles.select}
            value={selectedBot.asst_name}
            onChange={handleAssistantChange}
            itemText={
              assistantList?.find(
                (assistant) => assistant.id === selectedBot.asst_name
              )?.name
            }
          >
            {assistantList.map((assistant) => (
              <AppSelectOption key={assistant.id} value={assistant.id}>
                {assistant.name}
              </AppSelectOption>
            ))}
          </AppSelect>
        ) : (
          <AppLoading small />
        )}
        <div className={styles["switch-container"]}>
          <AppSwitch
            className={styles.switch}
            checked={selectedBot.log_message}
            onChange={(e) => handleSwitchChange(e, "log_message")}
          />
          <span className={styles["switch-label"]}>
            Записывать историю диалогов
          </span>
        </div>
        {/* <div className={styles["switch-container"]}>
          <AppSwitch
            className={styles.switch}
            checked={selectedBot.stream}
            onChange={(e) => handleSwitchChange(e, "stream")}
          />
          <span className={styles["switch-label"]}>
            Потоковый режим сообщений
          </span>
        </div> */}
      </div>
      {userInfo?.hide_security_bar ? (
        <></>
      ) : (
        <div className={styles["block-container"]}>
          <AppUnderlinedTitle
            className={styles.title}
            leftContent="Безопасность"
          >
            <AppSwitch
              className={styles.switch}
              checked={selectedBot.security_check}
              onChange={(e) => handleSwitchChange(e, "security_check")}
            />
          </AppUnderlinedTitle>
          <AppLabel className={styles.label}>Бот-администратор</AppLabel>
          <AppSelect
            className={styles.select}
            value={selectedBot.secure_bot_name}
            onChange={handleBotAdminChange}
            itemText={selectedBot.secure_bot_name || ""}
            disabled={!selectedBot.security_check || localIsChatsLoading}
          >
            {secureBotList.map((name) => (
              <AppSelectOption key={name} value={name}>
                {name}
              </AppSelectOption>
            ))}
          </AppSelect>
          {isChatsLoading || localIsChatsLoading ? (
            <AppLoading small />
          ) : (
            <AppSelect
              className={styles.select}
              value={selectedBot.secure_chat_id}
              onChange={handleSecureChatChange}
              itemText={getSecureChatName()}
              disabled={!selectedBot.security_check}
            >
              {getSecureChatList().map((chat) => (
                <AppSelectOption key={chat.id} value={chat.id}>
                  {chat.name}
                </AppSelectOption>
              ))}
            </AppSelect>
          )}
        </div>
      )}
      <div className={styles["block-container"]}>
        <AppUnderlinedTitle
          className={styles.title}
          leftContent="Дополнительные пользователи"
        >
          <AppButton
            buttonType="text"
            onClick={() => handleAddUser("white_list")}
            icon={<PlusIcon />}
          >
            Добавить
          </AppButton>
        </AppUnderlinedTitle>
        {!selectedUserList
          ? isChatsLoading && <AppLoading small />
          : selectedUserList.selectedWhitelistUsers.map((user) => (
              // eslint-disable-next-line react/jsx-indent
              <ChatbotUser
                key={user.selectId}
                userInfo={user}
                userOptions={selectedUserList.userOptions}
                onChange={(e) =>
                  handleUserChange(e, user.selectId, "white_list")
                }
                onDelete={(selectId: string) =>
                  handleUserDelete(selectId, "white_list")
                }
              />
            ))}
      </div>
      {userInfo?.hide_security_bar ? (
        <></>
      ) : (
        <div className={styles["block-container"]}>
          <AppUnderlinedTitle
            className={styles.title}
            leftContent="Черный список"
          >
            <AppButton
              buttonType="text"
              icon={<PlusIcon />}
              onClick={() => handleAddUser("black_list")}
            >
              Добавить
            </AppButton>
          </AppUnderlinedTitle>
          <div
            className={classNames(
              styles["blacklist-switch-container"],
              styles["switch-container"]
            )}
          >
            <span
              className={classNames(
                styles["blacklist-switch-label"],
                styles["switch-label"]
              )}
            >
              Ограничение по списку
            </span>
            <AppSwitch
              className={styles.switch}
              checked={selectedBot.check_validation}
              onChange={(e) => handleSwitchChange(e, "check_validation")}
            />
          </div>
          {!selectedUserList
            ? isChatsLoading && <AppLoading small />
            : selectedUserList.selectedBlacklistUsers.map((user) => (
                // eslint-disable-next-line react/jsx-indent
                <ChatbotUser
                  key={user.selectId}
                  userInfo={user}
                  userOptions={selectedUserList.userOptions}
                  onChange={(e) =>
                    handleUserChange(e, user.selectId, "black_list")
                  }
                  onDelete={(selectId: string) =>
                    handleUserDelete(selectId, "black_list")
                  }
                />
              ))}
        </div>
      )}

      <div className={styles["block-container"]}>
        <AppUnderlinedTitle className={styles.title} leftContent="Чаты" />
        {isChatsLoading || !assistantList ? (
          <AppLoading small />
        ) : selectedBot.chats && selectedBot.chats.length ? (
          <ChatbotTable
            dataRows={getTableRowData()}
            assistantList={assistantList}
            selectedAssistantId={selectedBot.asst_name || ""}
            setChangeAssistantDialogOpen={setChangeAssistantDialogOpen}
            setChangingChatId={setChangingChatId}
          />
        ) : (
          <AppLabel className={styles.label}>Нет чатов</AppLabel>
        )}
      </div>
    </div>
  );
};
