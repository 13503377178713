import { Dialog } from "../../../features/Dialog";
import { DialogsPage } from "../../../pages/DialogsPage";
import { appRouterPaths } from "../../../services/navigation/urls";
import type { AppRouteProps } from "../types";

export const dialogsPageRoute: AppRouteProps = {
  path: appRouterPaths.dialogs,
  element: <DialogsPage />,
  name: "Диалоги",
};

export const dialogPageRoute: AppRouteProps = {
  path: appRouterPaths.dialog,
  element: <Dialog />,
  name: "Ассистент",
};
