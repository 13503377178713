let classificatorFolderId = "";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  classificatorFolderId =
    process.env.REACT_APP_CLASSICATOR_TOOLS_FOLDER_ID || "";
} else {
  classificatorFolderId =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_FOLDER_ID;
}

let classificatorKey = "";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  classificatorKey = process.env.REACT_APP_CLASSICATOR_TOOLS_API_KEY || "";
} else {
  classificatorKey =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_API_KEY;
}

let classificatorModel = "yandexgpt/latest";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  classificatorModel = process.env.REACT_APP_CLASSICATOR_TOOLS_MODEL || "";
} else {
  classificatorModel =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_MODEL;
}

let classificatorApiUrl = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  classificatorApiUrl =
    process.env.REACT_APP_CLASSICATOR_TOOLS_JSON_URL || "/";
} else {
  classificatorApiUrl =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_JSON_URL;
}

export {
  classificatorFolderId,
  classificatorKey,
  classificatorModel,
  classificatorApiUrl,
};
