import { AppButton } from "../../../../shared/components/AppButton";
import { AppInputBase } from "../../../../shared/components/AppInputBase";
import { ClassInputContainer } from "./ClassInputContainer";
import { PlusIcon } from "../../../../shared/icons/PlusIcon";
import { TrashIcon } from "../../../../shared/icons/TrashIcon";
import classNames from "classnames";
import styles from "./styles.module.css";
import type { Label } from "../../../../services/api/instances/classificatorApi/types";

interface Props {
  className?: string;
  card: Label;
  handleDeleteVariant: (variantId: string) => void;
  handleAddSample: (variantId: string) => void;
  handleDeleteSample: (variantId: string, sampleId: string) => void;
  handleVariantDescriptionChange: (value: string, variantId: string) => void;
  handleVariantLabelChange: (value: string, variantId: string) => void;
  handleSampleInputChange: (
    value: string,
    variantId: string,
    sampleId: string
  ) => void;
}

export const ClassReviewCard: React.FC<Props> = ({
  className,
  card,
  handleDeleteVariant,
  handleAddSample,
  handleDeleteSample,
  handleVariantDescriptionChange,
  handleVariantLabelChange,
  handleSampleInputChange,
}) => {
  return (
    <div className={classNames(styles.content, className)}>
      <div className={styles["left-container"]}>
        <AppInputBase
          className={styles.select}
          small
          value={card.label}
          rows={undefined}
          placeholder="Вариант"
          onChange={(e) => handleVariantLabelChange(e.target.value, card.id!)}
        />
        <AppButton
          className={styles.button}
          icon={<TrashIcon />}
          buttonType="text"
          onClick={() => handleDeleteVariant(card.id!)}
        >
          Удалить
        </AppButton>
      </div>
      <div className={styles["right-container"]}>
        <AppInputBase
          className={classNames(styles.input, styles["top-input"])}
          small
          rows={undefined}
          value={card.description}
          placeholder="Описание"
          onChange={(e) =>
            handleVariantDescriptionChange(e.target.value, card.id!)
          }
        />
        {card?.samples.map((sample, index) => (
          <ClassInputContainer
            key={index}
            inputValue={sample.value}
            inputId={sample.id!}
            onDeleteInput={() => handleDeleteSample(card.id!, sample.id!)}
            onInputChange={(value: string) =>
              handleSampleInputChange(value, card.id!, sample.id!)
            }
          />
        ))}
        <AppButton
          icon={<PlusIcon />}
          buttonType="text"
          className={styles.button}
          onClick={() => handleAddSample(card.id!)}
        >
          Добавить
        </AppButton>
      </div>
    </div>
  );
};
