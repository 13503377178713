import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import type { ClassifierState } from "./types";
import {
  createClassifierAction,
  getAllClassifiersAction,
  getCompModelsAction, updateStorageObjectAction,
} from "./actions";
import { filteredClassifier } from "../helpers";

export function getAllClassifiersReducerBuilder(
  builder: ActionReducerMapBuilder<ClassifierState>
) {
  builder.addCase(getAllClassifiersAction.pending, (state) => {
    state.isClassifiersLoading = true;
  });
  builder.addCase(getAllClassifiersAction.fulfilled, (state, action) => {
    state.isClassifiersLoading = false;
    state.classifiers = filteredClassifier(action.payload.objects);
  });
  builder.addCase(getAllClassifiersAction.rejected, (state) => {
    state.isClassifiersLoading = false;
  });
}

export function updateClassifierReducerBuilder(
  builder: ActionReducerMapBuilder<ClassifierState>
) {
  builder.addCase(updateStorageObjectAction.fulfilled, (state, action) => {
    state.classifiers = state.classifiers.map((item) => {
      if (item.id === action.payload.id) {
        return action.payload;
      } else {
        return item;
      }
    });
  });
}

export function createClassifierReducerBuilder(
  builder: ActionReducerMapBuilder<ClassifierState>
) {
  builder.addCase(createClassifierAction.fulfilled, (state, action) => {
    state.classifiers = [...state.classifiers, action.payload];
    state.selectedClassifier = action.payload;
  });
}

export function getClassifierModelsReducerBuilder(
  builder: ActionReducerMapBuilder<ClassifierState>
) {
  builder.addCase(getCompModelsAction.pending, (state) => {
    state.isClassifiersLoading = true;
  });
  builder.addCase(getCompModelsAction.fulfilled, (state, action) => {
    state.models = action.payload.models;
    state.isClassifiersLoading = false;
  });
  builder.addCase(getCompModelsAction.rejected, (state) => {
    state.isClassifiersLoading = false;
  });
}
