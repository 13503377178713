import { AppButton } from "../AppButton";
import classNames from "classnames";
import styles from "./styles.module.css";
import React from "react";

interface Props {
  className?: string;
  leftContent?: string | React.ReactNode;
  buttonTitle?: boolean;
  buttonClassName?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

export const AppUnderlinedTitle: React.FC<Props> = ({
  className,
  leftContent,
  buttonTitle,
  buttonClassName,
  children,
  disabled,
  onClick,
}) => {
  return (
    <div className={classNames(styles.title, className)}>
      {buttonTitle ? (
        <AppButton
          className={buttonClassName}
          onClick={onClick}
          buttonType="text"
          disabled={disabled}
        >
          {leftContent}
        </AppButton>
      ) : (
        <div className={styles["left-content"]}>{leftContent || ""}</div>
      )}
      <div className={styles.absolute}>{children}</div>
    </div>
  );
};
