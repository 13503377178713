import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { ClassifierState } from "./types";
import type { CompModel } from "../../../services/api/methodsTypes";
import {
  getAllClassifiersReducerBuilder,
  updateClassifierReducerBuilder,
} from "./reducers";
import type { StorageObject } from "../../../services/api/instances/storageApi/types";
import type {
  ClassPostObjType,
  PredictionObject,
} from "../../../services/api/instances/classificatorApi/types";

const initialState: ClassifierState = {
  models: [],
  selectedModel: null,
  classifiers: [],
  selectedClassifier: null,
  classifierData: null,
  classifierResults: null,
  classifierName: "",
  isClassifiersLoading: false,
};

const classifierSlice = createSlice({
  name: "classifier",
  initialState,
  reducers: {
    setClassifiersAction: (state, action: PayloadAction<StorageObject[]>) => {
      state.classifiers = action.payload;
    },

    setSelectedModelAction: (state, action: PayloadAction<CompModel>) => {
      state.selectedModel = action.payload;
    },

    setSelectedClassifierAction: (
      state,
      action: PayloadAction<StorageObject | null>
    ) => {
      state.selectedClassifier = action.payload;
    },

    setClassifierDataAction: (
      state,
      action: PayloadAction<ClassPostObjType | null>
    ) => {
      state.classifierData = action.payload;
    },

    setClassifierResultsAction: (
      state,
      action: PayloadAction<PredictionObject | null>
    ) => {
      state.classifierResults = action.payload;
    },

    setClassifierNameAction: (state, action: PayloadAction<string>) => {
      state.classifierName = action.payload;
    },

    clearClassifiersStateAction: () => initialState,
  },
  extraReducers: (builder) => {
    getAllClassifiersReducerBuilder(builder);
    updateClassifierReducerBuilder(builder);
  },
});

export const classifierReducer = classifierSlice.reducer;
export const {
  setClassifiersAction,
  setSelectedModelAction,
  setSelectedClassifierAction,
  setClassifierDataAction,
  setClassifierResultsAction,
  setClassifierNameAction,
} = classifierSlice.actions;
