import { type ChangeEvent, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { AppButton } from "../../../shared/components/AppButton";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import type { ChartEntry } from "./MyClassChart";
import { MyClassChart } from "./MyClassChart";
import styles from "./styles.module.css";
import { AppInputBase } from "../../../shared/components/AppInputBase";
import { CopyButton } from "../../../shared/components/Buttons/CopyButton";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import {
  selectClassifierData,
  selectClassifierResults,
} from "../redux/selectors";
import { useClassifier } from "../hooks/use-classifier";
import type {
  ClassPostObjType,
  PredictionObject,
} from "../../../services/api/instances/classificatorApi/types";

interface Props {
  data: PredictionObject | null;
  leftData: ClassPostObjType["labels"];
  requestBody: () => string;
  getRequestBodyForCopy: () => string;
  isLoading: boolean;
  onClassification: () => void;
}

export const ClassRightContainer: React.FC<Props> = ({
  data,
  leftData,
  requestBody,
  getRequestBodyForCopy,
  isLoading,
  onClassification,
}) => {
  const classifierData = useAppSelector(selectClassifierData);
  const classifierResults = useAppSelector(selectClassifierResults);
  const { handleInputFieldChange } = useClassifier();
  const [isDisabled, setIsDisabled] = useState(true);

  const checkVariantUniqueness = (data: ClassPostObjType["labels"] = []) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].label === data[j].label) {
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    const validVariants =
      leftData?.filter((card) => card.label.trim() !== "") || [];
    const areVariantsUnique = checkVariantUniqueness(leftData);

    if (
      classifierData?.text_request &&
      validVariants.length >= 2 &&
      areVariantsUnique
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [classifierData, leftData]);

  let chartData: ChartEntry[] = [];

  if (data) {
    chartData = data.predictions
      .filter((item) => item.label)
      .map((item) => {
        return {
          label: item.label,
          value: item.confidence,
        };
      });
  }

  const chartRenderLogic = (isLoading: boolean) => {
    if (isLoading) {
      return (
        <div className={styles["loader-container"]}>
          <CircularProgress size={50} color="success" />
        </div>
      );
    }
    if (!isLoading && classifierResults)
      return <MyClassChart data={chartData} />;
  };

  return (
    <div className={styles["right-container"]}>
      <AppUnderlinedTitle
        className={styles.title}
        leftContent="Общая инструкция"
      />
      <AppInputBase
        small
        minRows={4}
        rows={undefined}
        placeholder="Общая инструкция"
        className={styles["text-area"]}
        value={classifierData?.prefix}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          handleInputFieldChange(event, "prefix")
        }
      />
      <AppUnderlinedTitle
        className={styles.title}
        leftContent="Классификация"
      />
      <AppInputBase
        small
        minRows={4}
        rows={undefined}
        placeholder="Классификация"
        value={classifierData?.text_request}
        className={styles["text-area"]}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          handleInputFieldChange(event, "text_request")
        }
      />
      <AppButton
        className={styles.button}
        buttonType="outlined"
        onClick={onClassification}
        disabled={isDisabled || isLoading}
      >
        Классифицировать
      </AppButton>
      <div className={styles["chart-container"]}>
        {chartRenderLogic(isLoading)}
      </div>
      <div className={styles["wrapper"]}>
        <pre>{requestBody()}</pre>
        <CopyButton
          onClick={() => navigator.clipboard.writeText(getRequestBodyForCopy())}
          position="absolute"
        />
      </div>
    </div>
  );
};
