import { useEffect, useRef } from "react";
import { AppChatMessage } from "./AppChatMessage";
import styles from "./styles.module.css";
import classNames from "classnames";
import type { Message } from "../../../../services/api/methodsTypes";
import { AppLoading } from "../../AppLoading";
import * as React from "react";

interface Props {
  className?: string;
  messages: Message[];
  isMessageLoading: boolean;
  customLoadingMessage: string;
  hasViewAssistantName?: boolean;
}

export const AppChat: React.FC<Props> = ({
  className,
  messages,
  isMessageLoading,
  customLoadingMessage,
  hasViewAssistantName,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Скролл в конец чата при новом сообщении
    // Без таймаута скролит в начало сообщения
    const timeout = setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, 1);

    return () => clearTimeout(timeout);
  }, [messages.length]);

  return (
    <>
      <div
        className={classNames(styles.chat, className)}
        ref={chatContainerRef}
      >
        {!messages?.length && !isMessageLoading ? (
          <span className={styles.loading}>Пустой чат</span>
        ) : null}
        {messages?.map((message) => {
          if (message.role !== "system") {
            return (
              <AppChatMessage
                key={message.id}
                message={message}
                assistantName={
                  !hasViewAssistantName
                    ? ""
                    : message?.metadata?.assistant_name === undefined
                    ? // eslint-disable-next-line quotes
                      `Ассистент отключен от "Диалогов"`
                    : message?.metadata?.assistant_name
                }
              />
            );
          }
        })}

        {isMessageLoading ? (
          <>
            <span className={styles.loading}>
              {customLoadingMessage || <AppLoading small />}
            </span>
          </>
        ) : null}
      </div>
    </>
  );
};
