import type { FC, DragEvent, ReactNode, ChangeEvent } from "react";
import { useState } from "react";
import styles from "./styles.module.css";
import type { DialogProps } from "@mui/material";
import type { AudioTranscriptionInfo, TranscriptionModeType } from "./types";
import { UploadZone } from "./components/UploadZone";
import classNames from "classnames";
import { AudioTranscriptionLayout } from "./components/AudioTranscriotionLayout";
import { ModalBasic } from "../../../shared/components/ModalBasic";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import {
  selectAudioFile,
  selectAudioTranscriptionInfo,
  selectIsFileTranscription,
  selectIsFileUpload,
  selectTranscriptionMode,
} from "./redux/selectors";
import {
  setAudioFileAction,
  setAudioTranscriptionInfo,
  setIsFileTranscriptionAction,
  setIsFileUploadAction,
  setTranscriptionModeAction,
} from "./redux/slice";
import { useAudioTranscription } from "./hook/use-audioTranscription";

interface Props extends DialogProps {
  closeModal?: () => void;
}

export const AudioTranscription: FC<Props> = ({ closeModal, ...props }) => {
  const { uploadAudioFile, getProcessingResult } = useAudioTranscription();
  const dispatch = useAppDispatch();
  const audioTranscriptionInfo = useAppSelector(selectAudioTranscriptionInfo);
  const isFileUpload = useAppSelector(selectIsFileUpload);
  const isFileTranscription = useAppSelector(selectIsFileTranscription);
  const file = useAppSelector(selectAudioFile);
  const status = useAppSelector(selectTranscriptionMode);
  const [controller, setController] = useState<AbortController | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];
    if (!e?.target?.files?.[0]) return;
    dispatch(setAudioFileAction(uploadedFile?.name as string));
    uploadAudioFile(uploadedFile as File);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    const uploadedFile = e.dataTransfer.files[0];
    dispatch(setAudioFileAction(uploadedFile.name));
    uploadAudioFile(uploadedFile as File);
  };

  const handleRecognize = () => {
    dispatch(setTranscriptionModeAction("processing" as TranscriptionModeType));
    const newController = new AbortController();
    setController(newController);
    getProcessingResult(newController.signal);
  };

  const handleAbort = () => {
    dispatch(
      setAudioTranscriptionInfo({
        ...(audioTranscriptionInfo as AudioTranscriptionInfo),
        progress: 0,
        status: "upload",
        text: "",
      })
    );
    dispatch(setIsFileUploadAction(true));
    dispatch(setIsFileTranscriptionAction(false));
    controller?.abort();
    dispatch(setTranscriptionModeAction("upload" as TranscriptionModeType));
  };

  return (
    <ModalBasic
      title={"Расшифровка аудио"}
      hasCloseButton
      closeModal={closeModal}
      className={styles.modal}
      hasFooter={true}
      onOk={handleRecognize}
      onCancel={handleAbort}
      disabledOkBtn={isFileUpload || isFileTranscription}
      {...props}
    >
      <div
        className={classNames(styles["modal-content"], {
          [styles["processing"]]: status !== "upload",
        })}
      >
        {status === "upload" && (
          <UploadZone dropHandler={handleDrop} onChange={handleFileChange} />
        )}
        {status !== "upload" && (
          <AudioTranscriptionLayout
            status={status}
            content={audioTranscriptionInfo?.text as string}
            fileName={file}
            progress={audioTranscriptionInfo?.progress as number}
          />
        )}
      </div>
    </ModalBasic>
  );
};
