import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createThread,
  getAssistants,
  getMessages,
  postCompMessage,
} from "../../../services/api/methods";
import type { Message, MessageList } from "../../../services/api/methodsTypes";
import type { RootState } from "../../../services/redux/types";

export const getStylistAssistantsAction = createAsyncThunk(
  "stylist/getAssistants",
  async () => {
    try {
      return await getAssistants();
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const createStylistThreadAction = createAsyncThunk(
  "stylist/createThread",
  async () => {
    try {
      return await createThread();
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const postStylistMessageAction = createAsyncThunk(
  "stylist/postCompMessage",
  async (message: Message, thunkAPI) => {
    try {
      const { thread } = (thunkAPI.getState() as RootState).stylistState;

      return await postCompMessage(thread?.id!, message);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getStylistMessagesAction = createAsyncThunk(
  "stylist/getMessages",
  async (_, thunkAPI) => {
    try {
      const { thread } = (thunkAPI.getState() as RootState).stylistState;

      const res: MessageList = await getMessages(thread?.id!);
      const newMessageList: MessageList = {
        ...res,
        data: res.data,
      };

      return newMessageList;
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);
