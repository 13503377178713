import { useAssistantsPage } from "./hooks/use-assistants-page";
import { AppDeleteDialog } from "../../shared/components/AppDialog/AppDeleteDialog";
import { useDeleteAssistantModal } from "./hooks/use-delete-assistant-modal";

import styles from "./styles.module.css";
import { AppSidebar } from "../../common/Sidebar";
import { Outlet } from "react-router-dom";

export const AssistantPage = () => {
  const {
    deleteAssistantInfo,
    isDeleteAssistantModalOpen,
    handleAssistantDelete,
    openDeleteAssistantModal,
    closeDeleteAssistantModal,
  } = useDeleteAssistantModal();

  const {
    selectedAssistant,
    isLoading,
    sidebarMenuItems,
    changeSelectedAssistant,
    addAssistant,
    isSendDisabled,
  } = useAssistantsPage();

  return (
    <div className={styles["page-layout"]}>
      <AppSidebar
        menuItems={sidebarMenuItems}
        defaultName="Ассистент"
        selectedId={selectedAssistant?.id}
        onSelect={changeSelectedAssistant}
        onAdd={addAssistant}
        onDelete={(id, name) => openDeleteAssistantModal(String(id), name)}
        isSendDisabled={isSendDisabled}
        isLoading={isLoading}
      />
      {selectedAssistant && <Outlet />}
      <AppDeleteDialog
        dialogOpen={isDeleteAssistantModalOpen}
        deletingInfo={deleteAssistantInfo}
        handleDialogClose={closeDeleteAssistantModal}
        onDelete={handleAssistantDelete}
      />
    </div>
  );
};
