import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createThread,
  getAssistantFunctions,
  getAssistants,
  getFileContent,
  getMessages,
  postCompMessage,
  updateAssistant,
} from "../../../services/api/methods";
import type {
  AssistantInfo,
  Message,
  MessageList,
} from "../../../services/api/methodsTypes";
import type { RootState } from "../../../services/redux/types";
import { addSelectedFieldToFunctions } from "../../../shared/utils/assistantPageFunctions";
import type { NavigateFunction } from "react-router-dom";
import {
  clearMessagesAction,
  setDialogNameAction,
  setDialogsAction,
  setSelectedDialogAction,
} from "./slice";
import {
  appRouterPaths,
  getDialogPagePath,
} from "../../../services/navigation/urls";
import { getThreadId } from "../helpers";
import {
  createStorageObject,
  deleteStorageObject,
  getAllStorageObjects,
  updateStorageObject,
} from "../../../services/api/instances/storageApi/methods";
import type {
  PostStorageObject,
  StorageObject,
} from "../../../services/api/instances/storageApi/types";

export const getDialogsAssistantAction = createAsyncThunk(
  "dialogs/getAssistants",
  async () => {
    try {
      return await getAssistants();
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getDialogsAction = createAsyncThunk(
  "dialogs/getAllDialogs",
  async () => {
    try {
      return await getAllStorageObjects("dialogs");
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const createDialogAction = createAsyncThunk(
  "dialogs/createDialog",
  async (postObj: PostStorageObject) => {
    try {
      return await createStorageObject(postObj, "dialogs");
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const createThreadAction = createAsyncThunk(
  "dialogs/createThread",
  async () => {
    try {
      return await createThread();
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const updateDialogAction = createAsyncThunk(
  "dialogs/updateDialog",
  async (body: { id: string; postObj: PostStorageObject }, _thunkAPI) => {
    try {
      const { id, postObj } = body;

      return await updateStorageObject(id, postObj, "dialogs");
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const deleteDialogAction = createAsyncThunk(
  "dialogs/deleteDialog",
  async (body: { id: string; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const { navigate, id } = body;
      const { dialogs, selectedDialog } = (thunkAPI.getState() as RootState)
        .dialogsState;
      const nextDialog = dialogs.filter(
        (dialog) => String(dialog.id) !== String(id)
      );
      const lastDialog = nextDialog[nextDialog.length - 1];

      thunkAPI.dispatch(setDialogsAction(nextDialog));

      if (!nextDialog[0]) {
        navigate(appRouterPaths.dialogs);
        thunkAPI.dispatch(setSelectedDialogAction(null));
        thunkAPI.dispatch(setDialogNameAction(""));
        thunkAPI.dispatch(setDialogsAction([]));
        thunkAPI.dispatch(clearMessagesAction());
      } else if (String(selectedDialog?.id) === String(id)) {
        thunkAPI.dispatch(setSelectedDialogAction(lastDialog));
        thunkAPI.dispatch(clearMessagesAction());
        thunkAPI.dispatch(setDialogNameAction(lastDialog.friendly_name));

        navigate(getDialogPagePath(String(lastDialog.id!)));
      }

      return await deleteStorageObject(id, "dialogs");
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const postDialogMessageAction = createAsyncThunk(
  "dialogs/postCompMessage",
  async (message: Message, thunkAPI) => {
    try {
      const { selectedDialog } = (thunkAPI.getState() as RootState)
        .dialogsState;
      const id = getThreadId(selectedDialog as StorageObject);

      return await postCompMessage(id, message);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getDialogMessagesAction = createAsyncThunk(
  "dialogs/getMessages",
  async (_, thunkAPI) => {
    try {
      const { selectedDialog, assistants } = (thunkAPI.getState() as RootState)
        .dialogsState;
      const id = getThreadId(selectedDialog as StorageObject);
      let res: MessageList = await getMessages(id);

      for (let i = 0; i < res.data.length; i++) {
        const modifiedMessage: Message = await proccessLinkedFiles(res.data[i]);
        res = {
          ...res,
          data: res.data.map((item) => {
            const currentAssistant = assistants.find(
              (assistant) => assistant.id === item.assistant_id
            );
            if (modifiedMessage.id === item.id) {
              modifiedMessage.metadata = {
                assistant_name: currentAssistant?.name,
              };
              return modifiedMessage;
            }
            if (item.role === "assistant" && currentAssistant) {
              item.metadata = { assistant_name: currentAssistant.name };
              return item;
            }
            return item;
          }),
        };
      }
      return res;
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getDialogFunctionsAction = createAsyncThunk(
  "dialogs/getFunctions",
  async (id: string, thunkAPI) => {
    try {
      const { selectedAssistant } = (thunkAPI.getState() as RootState)
        .dialogsState;
      const functions = await getAssistantFunctions(id);

      return addSelectedFieldToFunctions(functions, selectedAssistant!.tools);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

const proccessLinkedFiles = (message: Message): Promise<Message> => {
  const linkedFiles = message.content[0]?.text.annotations;
  if (!linkedFiles.length) {
    return Promise.resolve(message);
  }

  const promises: Promise<[Blob, string]>[] = linkedFiles
    .filter((linkedFile) => linkedFile.file_path?.file_id)
    .map((linkedFile) => {
      const fileId = linkedFile.file_path.file_id;
      const fileName = linkedFile.text.slice(
        linkedFile.text.lastIndexOf("/") + 1
      );
      return getFileContent(fileId).then((blob) => [blob, fileName]);
    });

  return Promise.all(promises).then((res) => {
    let newMessageText: string = message.content[0]?.text.value || "";

    res.forEach(([blob, fileName]) => {
      const newUrl = URL.createObjectURL(blob);
      // TODO: Название файла стоит передавать в новом localAssistantMessage.fileName и
      // настроить правильное отображение файла в сообщениях AppChatMessage
      const urlWithFileName = `#downloadlink===${fileName}===${newUrl}`;
      // Находим индекс первой sandbox ссылки в сообщении
      const linkRegex = /\]\(sandbox:/;
      const match = newMessageText.match(linkRegex);
      const startLinkIndex = match?.index;
      if (!startLinkIndex) return;
      const endLinkIndex = newMessageText.indexOf(")", startLinkIndex);

      if (startLinkIndex !== -1 && endLinkIndex !== -1) {
        const newLink = newMessageText.substring(
          startLinkIndex + 2,
          endLinkIndex
        );
        newMessageText = newMessageText.replace(newLink, urlWithFileName);
      }
    });

    return {
      ...message,
      content: [
        {
          ...message.content[0],
          text: {
            ...message.content[0].text,
            value: newMessageText,
          },
        },
      ],
    };
  });
};

export const updateMessagesAction = createAsyncThunk(
  "dialogs/updateMessages",
  async (_, thunkAPI) => {
    try {
      const { selectedDialog, assistants } = (thunkAPI.getState() as RootState)
        .dialogsState;
      const id = getThreadId(selectedDialog as StorageObject);
      const res: MessageList = await getMessages(id);
      const modifiedMessage: Message = await proccessLinkedFiles(
        res.data[res.data.length - 1]
      );
      const newMessageList: MessageList = {
        ...res,
        data: res.data.map((item) => {
          const currentAssistant = assistants.find(
            (assistant) => assistant.id === item.assistant_id
          );
          if (modifiedMessage.id === item.id) {
            modifiedMessage.metadata = {
              assistant_name: currentAssistant?.name,
            };
            return modifiedMessage;
          }
          if (item.role === "assistant" && currentAssistant) {
            item.metadata = { assistant_name: currentAssistant.name };
            return item;
          }
          return item;
        }),
      };

      return newMessageList;
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const saveUpdatedAssistantAction = createAsyncThunk(
  "dialogs/updateAssistant",
  async (body: AssistantInfo) => {
    try {
      const res = await updateAssistant({
        ...body,
        description: "",
        response_format:
          body.response_format === "auto"
            ? "auto"
            : {
                type: "json_object",
              },
      });

      return {
        ...res,
        response_format:
          res.response_format === "auto" ||
          (res.response_format as any).type === "text"
            ? "auto"
            : "json_object",
      };
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);
