import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { StylistState } from "./types";
import {
  getAssistantsReducerBuilder,
  getStylistMessagesReducerBuilder,
  getStylistThreadReducerBuilder,
  postStylistMessageReducerBuilder,
} from "./reducers";
import type { StylistMode } from "../StylistMain/types";
import type { AssistantInfo } from "../../../services/api/methodsTypes";

const initialState: StylistState = {
  assistants: [],
  selectedAssistant: null,
  thread: null,
  originalText: "",
  result: "",
  dateUpdate: "",
  messages: [],
  stylistMode: "вручную",
  isTyping: false,
};

const stylistSlice = createSlice({
  name: "stylist",
  initialState,
  reducers: {
    setSelectedAssistantAction: (
      state,
      action: PayloadAction<AssistantInfo>
    ) => {
      state.selectedAssistant = action.payload;
    },

    setStylistMode: (state, action: PayloadAction<StylistMode>) => {
      state.stylistMode = action.payload;
    },

    setStylistDateUpdate: (state, action: PayloadAction<string>) => {
      state.dateUpdate = action.payload;
    },

    setStylistOriginalText: (state, action: PayloadAction<string>) => {
      state.originalText = action.payload;
    },

    setStylistResult: (state, action: PayloadAction<string>) => {
      state.result = action.payload;
    },

    setStylistTyping: (state, action: PayloadAction<boolean>) => {
      state.isTyping = action.payload;
    },
  },
  extraReducers: (builder) => {
    getAssistantsReducerBuilder(builder);
    getStylistThreadReducerBuilder(builder);
    postStylistMessageReducerBuilder(builder);
    getStylistMessagesReducerBuilder(builder);
  },
});

export const stylistReducer = stylistSlice.reducer;
export const {
  setSelectedAssistantAction,
  setStylistOriginalText,
  setStylistResult,
  setStylistMode,
  setStylistDateUpdate,
  setStylistTyping,
} = stylistSlice.actions;
