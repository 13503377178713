import { useState, useEffect, useRef } from "react";
import { AppIconButton } from "../../../../../shared/components/AppIconButton";
import { CopyIcon } from "../../../../../shared/icons/CopyIcon";
import { remark } from "remark";
import avatarImg from "../../../../assets/img/bot-avatar.png";
import html from "remark-html";
import classNames from "classnames";
import styles from "./styles.module.css";
import type { Message } from "../../../../../services/api/methodsTypes";

interface Props {
  message: Message;
  assistantName?: string;
  hasViewAssistantName?: boolean;
}

const COPY_ICON = `
  <svg
    class=${styles["copy-code-icon"]}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 5.33329V3.99996C10.6667 3.64634 10.5263 3.3072 10.2762 3.05715C10.0262 2.8071 9.68704 2.66663 9.33341 2.66663H4.00008C3.64646 2.66663 3.30732 2.8071 3.05727 3.05715C2.80722 3.3072 2.66675 3.64634 2.66675 3.99996V9.33329C2.66675 9.68691 2.80722 10.0261 3.05727 10.2761C3.30732 10.5261 3.64646 10.6666 4.00008 10.6666H5.33341M5.33341 6.66663C5.33341 6.313 5.47389 5.97387 5.72394 5.72382C5.97399 5.47377 6.31313 5.33329 6.66675 5.33329H12.0001C12.3537 5.33329 12.6928 5.47377 12.9429 5.72382C13.1929 5.97387 13.3334 6.313 13.3334 6.66663V12C13.3334 12.3536 13.1929 12.6927 12.9429 12.9428C12.6928 13.1928 12.3537 13.3333 12.0001 13.3333H6.66675C6.31313 13.3333 5.97399 13.1928 5.72394 12.9428C5.47389 12.6927 5.33341 12.3536 5.33341 12V6.66663Z"
      stroke="#7A909C"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const AppChatMessage: React.FC<Props> = ({ message, assistantName }) => {
  const messageText =
    message.content.find((item) => item.type === "text")?.text?.value || "";
  const messageAttachment = message.attachments?.find(
    (item) => item.file_id
  )?.file_id;
  const [htmlContent, setHtmlContent] = useState<string>(messageText);
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const processMarkdown = async () => {
      const processedContent = await remark().use(html).process(messageText);

      const assistantNameValue =
        assistantName?.length === 0 ? "" : `<h3>${assistantName}:</h3>`;
      const newDiv = document.createElement("div");
      newDiv.innerHTML = `${assistantNameValue} ${processedContent.toString()}`;

      // Больше паддинг для длинных сообщений
      if (messageRef.current && newDiv.childNodes.length > 2) {
        messageRef.current.style.padding = "var(--gap-4xs) 0";
      }

      // Иконка копирования кода
      newDiv.querySelectorAll("pre").forEach((preElement) => {
        preElement.insertAdjacentHTML("beforeend", COPY_ICON);
      });

      // Ссылка на скачивание блоба
      newDiv.querySelectorAll("a").forEach((aElement) => {
        aElement.setAttribute("target", "_blank");
        if (aElement.href.includes("#downloadlink===")) {
          const substrings = aElement.href.split("===");
          const fileName = substrings[1];
          const url = substrings[2];

          // decodeURIComponent для русских названий файлов
          aElement.href = url;
          aElement.setAttribute("download", decodeURIComponent(fileName));

          // // Кнопка скачивания в конце сообщения (для каждого файла)
          // const downloadButton = document.createElement("button");
          // downloadButton.classList.add(styles["download-button"]);
          // downloadButton.innerText = "Скачать";
          // downloadButton.addEventListener("click", () => {
          //   aElement.click();
          // });
          // newDiv.appendChild(downloadButton);
        }
      });

      setHtmlContent(newDiv.innerHTML);
    };

    // Отдельная функция для JSON ответа чтобы сохранить отступы
    const processJson = () => {
      const newDiv = document.createElement("div");
      const preElement = document.createElement("pre");
      const codeElement = document.createElement("code");

      codeElement.textContent = messageText.trim();
      preElement.appendChild(codeElement);
      newDiv.appendChild(preElement);

      // Иконка копирования кода
      newDiv.querySelectorAll("pre").forEach((element) => {
        element.insertAdjacentHTML("beforeend", COPY_ICON);
      });

      if (messageRef.current) messageRef.current.style.padding = "0";

      setHtmlContent(newDiv.innerHTML);
    };

    // Добавляем привязанный к сообщению файл
    const processUserMessage = () => {
      const newDiv = document.createElement("div");
      newDiv.innerHTML = messageText;

      // Больше паддинг для длинных сообщений
      if (messageRef.current && newDiv.childNodes.length > 2) {
        messageRef.current.style.padding = "var(--gap-4xs) 0";
      }

      if (messageAttachment) {
        const fileTag = document.createElement("div");
        fileTag.classList.add(styles["file-tag"]);
        fileTag.innerHTML = message.metadata?.[messageAttachment];
        newDiv.appendChild(fileTag);
      }

      setHtmlContent(newDiv.innerHTML);
    };

    if (message.role === "assistant" && !isJson(messageText)) {
      processMarkdown();
    } else if (message.role === "assistant" && isJson(messageText)) {
      processJson();
    } else {
      processUserMessage();
    }
  }, [messageText]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.right]: message.role === "user",
      })}
    >
      {message.role === "assistant" ? (
        <img className={styles.avatar} src={avatarImg} alt="avatar" />
      ) : null}
      <div className={classNames(styles.message, styles[message.role])}>
        <div
          className={styles.inner}
          ref={messageRef}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          onClick={(e: any) => {
            let text = "";
            if (e.target.toString().includes("SVGSVGElement")) {
              text = e.target.parentElement.children[0].innerText;
              console.log(text);
            } else if (e.target.toString().includes("SVGPathElement")) {
              text = e.target.parentElement.parentElement.children[0].innerText;
            }

            if (text !== "") navigator.clipboard.writeText(text);
          }}
        />
      </div>
      <div className={styles["bottom-container"]}>
        {message.role === "assistant" && (
          <AppIconButton
            className={styles["copy-button"]}
            disableRipple
            onClick={() => {
              navigator.clipboard.writeText(messageText);
            }}
          >
            <CopyIcon />
          </AppIconButton>
        )}
      </div>
    </div>
  );
};

function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
