import type { FC, ReactNode } from "react";
import type { TranscriptionModeType } from "../../types";
import styles from "./styles.module.css";
import { FileUploadIndicator } from "../FileUploadIndicator";
import { TranscriptionProgressBar } from "../TrancriptionProgressBar";
import { AudioTranscriptionSuccess } from "../AudioTranscriptionSuccess/indes";

interface Props {
  status: TranscriptionModeType;
  content: string;
  progress: number;
  fileName: string;
}

export const AudioTranscriptionLayout: FC<Props> = ({
  status,
  content,
  progress,
  fileName,
}) => {
  return (
    <div className={styles.container}>
      {status === "loading" && <FileUploadIndicator />}

      {status === "ready" && <div className={styles.ready}>{content}</div>}

      {status === "processing" && (
        <TranscriptionProgressBar progress={progress} fileName={fileName} />
      )}

      {status === "success" && <AudioTranscriptionSuccess content={content} />}

      {status === "failed" && (
        <div className={styles.error}>
          В процессе обработки файла произошла ошибка. Попробуйте ещё раз
        </div>
      )}

      {status === "error" && (
        <div className={styles.error}>
          В процессе загрузки файла произошла ошибка. Нажмите кнопку
          &quot;Отмена&quot; и попробуйте загрузить файл ещё раз
        </div>
      )}
    </div>
  );
};
