import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../services/redux/types";

export const selectClassifierState = (state: RootState) =>
  state.classifierState;

export const selectClassifiers = createSelector(
  selectClassifierState,
  (state) => {
    return state.classifiers;
  }
);

export const selectSelectedClassifier = createSelector(
  selectClassifierState,
  (state) => {
    return state.selectedClassifier;
  }
);

export const selectModels = createSelector(selectClassifierState, (state) => {
  return state.models;
});

export const selectSelectedModel = createSelector(
  selectClassifierState,
  (state) => {
    return state.selectedModel;
  }
);

export const selectClassifierData = createSelector(
  selectClassifierState,
  (state) => {
    return state.classifierData;
  }
);

export const selectClassifierResults = createSelector(
  selectClassifierState,
  (state) => {
    return state.classifierResults;
  }
);

export const selectClassifierName = createSelector(
  selectClassifierState,
  (state) => {
    return state.classifierName;
  }
);

export const selectIsClassifiersLoading = createSelector(
  selectClassifierState,
  (state) => {
    return state.isClassifiersLoading;
  }
);
