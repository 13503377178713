import type { StorageObject } from "../../../services/api/instances/storageApi/types";

export const filteredClassifier = (classifier: StorageObject[]) => {
  const filterClassifier = classifier.filter((item) => {
    return (
      JSON.parse(item.value).thread_id !== "" && item.children?.length === 0
    );
  });
  return filterClassifier;
};
