import { useEffect } from "react";
import type { AssistantInfo } from "../../../services/api/methodsTypes";
import {
  selectAssistants,
  selectIsAssistantsLoading,
  selectIsModelsLoading,
  selectIsSendDisabled,
  selectIsStoresLoading,
  selectModels,
  selectSelectedAssistant,
} from "../../../features/Assistants/redux/selectors";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import {
  createNewAssistantAction,
  getAssistantsAction,
  getCompModelsAction,
  getStoresAction,
} from "../../../features/Assistants/redux/actions";
import {
  clearMessagesAction,
  setSelectedAssistantAction,
  setThreadAction,
} from "../../../features/Assistants/redux/slice";
import { showError } from "../../../shared/utils/showError/showError";
import { sortAssistantData } from "../../../shared/utils/sortAssistants";
import { useNavigate, useParams } from "react-router-dom";
import { getAssistantPagePath } from "../../../services/navigation/urls";

export const useAssistantsPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id?: string }>();
  const assistants = useAppSelector(selectAssistants);
  const models = useAppSelector(selectModels);
  const isStoresLoading = useAppSelector(selectIsStoresLoading);
  const isModelsLoading = useAppSelector(selectIsModelsLoading);
  const isAssistantsLoading = useAppSelector(selectIsAssistantsLoading);
  const selectedAssistant: AssistantInfo | null = useAppSelector(
    selectSelectedAssistant
  );
  const isLoading = isStoresLoading || isModelsLoading || isAssistantsLoading;
  const isSendDisabled = useAppSelector(selectIsSendDisabled);
  const navigate = useNavigate();
  const sidebarMenuItems =
    sortAssistantData(assistants).map((assistant) => ({
      id: assistant.id!,
      name: assistant.name,
    })) || [];

  useEffect(() => {
    dispatch(getAssistantsAction())
      .unwrap()
      .then((res) => {
        const sortedAssistants = sortAssistantData(res.data);
        const currentAssistant = sortedAssistants.find(
          (item) => item.id === id
        );
        const assistantId = currentAssistant?.id || sortedAssistants[0]?.id;
        if (assistantId) {
          changeSelectedAssistant(assistantId);
        }
        dispatch(getStoresAction());
      });
    dispatch(getCompModelsAction());
  }, []);

  const changeSelectedAssistant = (id: string) => {
    dispatch(clearMessagesAction());
    dispatch(setThreadAction(null));
    dispatch(setSelectedAssistantAction(id));
    navigate(getAssistantPagePath(id));
  };

  const addAssistant = () => {
    if (!models?.length) {
      showError("Нет моделей");
      return;
    }
    dispatch(createNewAssistantAction())
      .unwrap()
      .then((res) => {
        changeSelectedAssistant(res?.id!);
      });
  };

  return {
    selectedAssistant,
    isLoading,
    sidebarMenuItems,
    changeSelectedAssistant,
    addAssistant,
    isSendDisabled,
  };
};
