import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import type { StylistState } from "./types";
import {
  createStylistThreadAction,
  getStylistAssistantsAction,
  getStylistMessagesAction,
  postStylistMessageAction,
} from "./actions";
import { sortAssistantData } from "../../../shared/utils/sortAssistants";

export function getAssistantsReducerBuilder(
  builder: ActionReducerMapBuilder<StylistState>
) {
  builder.addCase(getStylistAssistantsAction.fulfilled, (state, action) => {
    state.assistants = sortAssistantData(action.payload.data);
  });
}

export function getStylistThreadReducerBuilder(
  builder: ActionReducerMapBuilder<StylistState>
) {
  builder.addCase(createStylistThreadAction.fulfilled, (state, action) => {
    state.thread = action.payload;
  });
}

export function postStylistMessageReducerBuilder(
  builder: ActionReducerMapBuilder<StylistState>
) {
  builder.addCase(postStylistMessageAction.fulfilled, (state, action) => {
    state.messages = state.messages.map((item, index) => {
      if (state.messages.length - 1 === index) {
        return action.payload;
      } else {
        return item;
      }
    });
  });
}

export function getStylistMessagesReducerBuilder(
  builder: ActionReducerMapBuilder<StylistState>
) {
  builder.addCase(getStylistMessagesAction.fulfilled, (state, action) => {
    state.messages = action.payload.data;
  });
}
