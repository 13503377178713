import axios from "axios";
import { multiUserApi } from "../../../../shared/utils/multiUserFunctions";
import { audioTranscriptionToken } from "./constants";

let APP_AUDIO_TRANSCRIPTION_API_URL = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  APP_AUDIO_TRANSCRIPTION_API_URL =
    process.env.REACT_APP_AUDIO_TRANSCRIPTION_URL || "/";
} else {
  APP_AUDIO_TRANSCRIPTION_API_URL =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_AUDIO_TRANSCRIPTION_URL;
}

export let appAudioTranscriptionApi = axios.create({
  baseURL: APP_AUDIO_TRANSCRIPTION_API_URL,
});

export const recreateAppAudioTranscriptionApi = (
  setUserInfo: (value: null) => void,
  replacementToken: boolean,
  baseUrl?: string
) => {
  appAudioTranscriptionApi = axios.create({
    baseURL: baseUrl ? baseUrl : APP_AUDIO_TRANSCRIPTION_API_URL,
  });

  appAudioTranscriptionApi.interceptors.request.use((request) => {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${audioTranscriptionToken}`;
      request.headers["X-Keycloak-Token"] =
        multiUserApi.getUserKey("access_token");
    }
    if (replacementToken) {
      request.headers["OpenAI-Beta"] = "assistants=v2";
    }
    return request;
  });

  appAudioTranscriptionApi.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        if (multiUserApi.parseToArray().length > 1) {
          multiUserApi.removeUser();
          window.location.reload();
        } else {
          multiUserApi.removeUser();
          setUserInfo(null);
        }
      }
      return Promise.reject(error);
    }
  );
};
