import styles from "./styles.module.css";
import { ResizableWrapper } from "../../../shared/components/ResizableWrapper";
import { useAssistant } from "../hooks/use-assistants";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import {
  addMessageAction,
  clearMessagesAction,
  setFunctionsAction,
  setIsMessageLoadingAction,
  setIsSendDisabledAction,
} from "../redux/slice";
import type {
  AssistantInfo,
  AssistantNewFunctionType,
  AssistantToolsObject,
  Message,
} from "../../../services/api/methodsTypes";
import {
  createThreadAction,
  postCompMessageAction,
  saveUpdatedAssistantAction,
  updateMessagesAction,
} from "../redux/actions";
import { useState } from "react";
import {
  addAssistantFunction,
  createRun,
  getAssistantFunctionSchema,
} from "../../../services/api/methods";
import { showError } from "../../../shared/utils/showError/showError";
import { AppNewFunctionDialog } from "../../../shared/components/AppDialog/AppNewFunctionDialog";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import { selectIsSendDisabled } from "../redux/selectors";
import { AssistantSettings } from "./AssistantSettings";
import { DialoguesSwitcher } from "./DialogueSwitcher";
import { Chat } from "../../../common/Chat";
import { useChat } from "../../../common/hooks/useChat";

export const Assistant = () => {
  const dispatch = useAppDispatch();
  const {
    selectedAssistant,
    thread,
    messages,
    models,
    stores,
    isMessageLoading,
    functions,
  } = useAssistant();
  const {
    message,
    customLoadingMessage,
    attachedFile,
    isFileLoading,
    checkStatus,
    setMessage,
    setAttachedFile,
    handleFileUpload,
    handleSendMessage,
  } = useChat();
  const isSendDisabled = useAppSelector(selectIsSendDisabled);
  const [isNewFunctionModalOpen, setIsNewFunctionModalOpen] = useState(false);

  const isSendDisabledActionFalse = () => {
    dispatch(setIsSendDisabledAction(false));
  };

  const isSendDisabledTrue = () => {
    dispatch(setIsSendDisabledAction(true));
  };

  const updatedMessages = () => {
    dispatch(updateMessagesAction())
      .unwrap()
      .finally(() => {
        dispatch(setIsSendDisabledAction(false));
      });
  };

  const addMessage = (messageObj: Message) => {
    dispatch(addMessageAction(messageObj));
  };

  const postMessage = (messageObj: Message) => {
    dispatch(postCompMessageAction(messageObj))
      .unwrap()
      .then(() => {
        const runObj = {
          assistant_id: selectedAssistant?.id!,
        };
        console.log("assistantFunctions 1", functions);
        createRun(thread?.id!, runObj)
          .then((run) => {
            checkStatus(
              thread?.id as string,
              run,
              updatedMessages,
              isSendDisabledActionFalse,
              functions,
              selectedAssistant
            );
          })
          .catch((error) => {
            dispatch(setIsSendDisabledAction(false));
            dispatch(setIsMessageLoadingAction(false));
            console.error(error);
          });
      })
      .catch((error) => {
        dispatch(setIsSendDisabledAction(false));
        dispatch(setIsMessageLoadingAction(false));
        console.error(error);
      });
  };

  const handleAddFunction = (
    url: string,
    friendlyName: string,
    isChecked: boolean
  ) => {
    const functionName = url.split("/").pop();
    if (!functionName) {
      showError("Не удалось получить название функции из ссылки");
      return;
    }
    if (functions.find((func) => func.function_name === functionName)) {
      showError("Эта функция уже добавлена для ассистента");
      return;
    }

    getAssistantFunctionSchema(selectedAssistant.id!, functionName)
      .then((res) => {
        const functionPostObj: AssistantNewFunctionType = {
          function_name: functionName,
          endpoint: url,
          credentials: isChecked,
          friendly_name: friendlyName,
          asst_name: selectedAssistant.id!,
        };

        addAssistantFunction(functionPostObj).then(() => {
          const functionInfoObject: AssistantToolsObject = {
            type: "function",
            function: res.schema,
          };
          const updatedAssistant: AssistantInfo = {
            ...selectedAssistant,
            tools: [...selectedAssistant.tools, functionInfoObject],
          };
          dispatch(
            setFunctionsAction([
              ...functions,
              {
                function_name: functionName,
                friendly_name: friendlyName,
                selected: true,
              },
            ])
          );
          saveAssistant(updatedAssistant);
        });
      })
      .catch(() => {
        showError("Функция с таким url не найдена");
      });
  };

  const openCreateFunctionModal = () => setIsNewFunctionModalOpen(true);
  const closeCreateFunctionModal = () => setIsNewFunctionModalOpen(false);

  const handleClearChat = () => {
    setMessage("");
    dispatch(clearMessagesAction());
    dispatch(createThreadAction());
  };

  const saveAssistant = (assistant: AssistantInfo) => {
    dispatch(saveUpdatedAssistantAction(assistant));
  };

  const handleSend = () => {
    handleSendMessage(isSendDisabledTrue, addMessage, postMessage);
  };

  return (
    <>
      <ResizableWrapper
        hasButton={true}
        leftBlock={
          <AssistantSettings
            models={models}
            selectedAssistant={selectedAssistant!}
            saveAssistant={saveAssistant}
            stores={stores}
            functions={functions}
            openCreateFunctionModal={openCreateFunctionModal}
            isSendDisabled={isSendDisabled}
          />
        }
        rightBlock={
          <Chat
            additionalContent={<DialoguesSwitcher />}
            handleClearChat={handleClearChat}
            className={styles.chat}
            userMessage={message}
            setUserMessage={setMessage}
            handleSend={handleSend}
            handleFileUpload={handleFileUpload}
            isDisabled={isSendDisabled}
            messages={messages}
            attachedFile={attachedFile}
            setAttachedFile={setAttachedFile}
            isFileLoading={isFileLoading}
            isMessageLoading={isMessageLoading || isSendDisabled}
            customLoadingMessage={customLoadingMessage}
          />
        }
        leftBlockSize={40}
        rightBlockSize={60}
        rightBlockMinSize={50}
      />
      <AppNewFunctionDialog
        dialogOpen={isNewFunctionModalOpen}
        handleDialogClose={closeCreateFunctionModal}
        handleAddFunction={handleAddFunction}
      />
    </>
  );
};
