import type { ButtonProps } from "@mui/material";
import { CopyIcon } from "../../../../shared/icons/CopyIcon";
import styles from "../styles.module.css";
import { AppButton } from "../../../../shared/components/AppButton";
import type { FC } from "react";

interface Props extends ButtonProps {
  handleCopyObject?: () => void;
}

export const CopyObjectButton: FC<Props> = ({ handleCopyObject, ...props }) => {
  return (
    <AppButton
      buttonType={"text"}
      onClick={handleCopyObject}
      icon={<CopyIcon />}
      className={styles.button}
      {...props}
    >
      Копировать объект
    </AppButton>
  );
};
