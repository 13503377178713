import { useNavigate, useParams } from "react-router-dom";
import {
  selectClassifierName,
  selectClassifiers,
  selectIsClassifiersLoading,
  selectSelectedClassifier,
} from "../../../features/Classification/redux/selectors";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import { useEffect } from "react";
import {
  createClassifierAction,
  deleteClassifierAction,
  getAllClassifiersAction,
} from "../../../features/Classification/redux/actions";
import {
  setClassifierDataAction,
  setClassifierNameAction,
  setClassifierResultsAction,
  setClassifiersAction,
  setSelectedClassifierAction,
} from "../../../features/Classification/redux/slice";
import {
  appRouterPaths,
  getClassifierPagePath,
} from "../../../services/navigation/urls";
import { generateStringId } from "../../../shared/utils/generateStringId";
import type { ClassPostObjType } from "../../../services/api/instances/classificatorApi/types";
import type {
  PostStorageObject,
  StorageObject,
} from "../../../services/api/instances/storageApi/types";
import {classificatorModel} from "../../../services/api/instances/classificatorApi/constants";

const classifierInitialObject: ClassPostObjType = {
  prefix: "",
  labels: [],
  text_request: "",
  folder_id: "",
  api_key: "",
  model: classificatorModel,
};

export const useClassifiersPage = () => {
  const dispatch = useAppDispatch();
  const classifiers = useAppSelector(selectClassifiers);
  const selectedClassifier = useAppSelector(selectSelectedClassifier);
  const isClassifierLoading = useAppSelector(selectIsClassifiersLoading);
  const classifierName = useAppSelector(selectClassifierName);
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    dispatch(getAllClassifiersAction())
      .unwrap()
      .then((response) => {
        const activeClassifierById = response.objects.find(
          (item: StorageObject) => String(item.id) === id
        );
        const activeClassifier = activeClassifierById || response.objects[0];

        if (!activeClassifier) {
          navigate(appRouterPaths.classifiers);
          return;
        }

        const activeClassifierValue: ClassPostObjType = activeClassifier.value
          ? JSON.parse(activeClassifier.value as string).classifier_object
          : classifierInitialObject;
        const nextClassifierValue = {
          ...activeClassifierValue,
          labels: activeClassifierValue.labels.map((item) => {
            return {
              ...item,
              id: generateStringId(),
            };
          }),
        };
        dispatch(
          setSelectedClassifierAction(activeClassifier as StorageObject)
        );
        dispatch(setClassifierDataAction(nextClassifierValue));
        dispatch(
          setClassifierNameAction(activeClassifier?.friendly_name as string)
        );

        navigate(getClassifierPagePath(String(activeClassifier.id)));
      });
  }, []);

  const addClassifier = () => {
    const classifier = {
      friendly_name: `Классификатор ${classifiers.length + 1}`,
      value: JSON.stringify({
        classifier_object: classifierInitialObject,
      }),
    };
    dispatch(setClassifierDataAction(classifierInitialObject));
    dispatch(createClassifierAction(classifier as PostStorageObject))
      .unwrap()
      .then((response) => {
        const newClassifiers = [...classifiers, response];
        dispatch(setSelectedClassifierAction(response));
        dispatch(setClassifiersAction(newClassifiers));
        dispatch(setClassifierNameAction(response.friendly_name));
        dispatch(setClassifierResultsAction(null));
        navigate(getClassifierPagePath(String(response.id)));
      });
  };

  const copyObject = () => {
    const copyClassifier = {
      friendly_name: `Классификатор ${classifiers.length + 1}`,
      value: selectedClassifier?.value,
    };
    dispatch(createClassifierAction(copyClassifier as PostStorageObject))
      .unwrap()
      .then((response) => {
        const newClassifiers = [...classifiers, response];
        dispatch(setSelectedClassifierAction(response));
        dispatch(setClassifiersAction(newClassifiers));
        dispatch(setClassifierNameAction(response.friendly_name));
        navigate(getClassifierPagePath(String(response.id)));
        dispatch(setClassifierResultsAction(null));
      });
  };

  const handleSelectClassifier = (id: string) => {
    const classifier = classifiers.find((item) => {
      return String(item.id) === String(id);
    });

    if (String(selectedClassifier?.id) !== String(id)) {
      dispatch(setClassifierResultsAction(null));
    }
    const classifierData = classifier?.value
      ? JSON.parse(classifier?.value as string).classifier_object
      : classifierInitialObject;
    dispatch(setClassifierDataAction(classifierData));
    dispatch(setSelectedClassifierAction(classifier as StorageObject));
    dispatch(setClassifierNameAction(classifier?.friendly_name as string));
    navigate(getClassifierPagePath(id));
  };

  const handleDeletedClassifier = (id: string, name: string) => {
    dispatch(deleteClassifierAction({ id, navigate }));
  };

  return {
    classifiers,
    selectedClassifier,
    isClassifierLoading,
    classifierName,
    addClassifier,
    copyObject,
    handleSelectClassifier,
    handleDeletedClassifier,
  };
};
