import { type ChangeEvent, type FC, useEffect, useState } from "react";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import styles from "./styles.module.css";
import classNames from "classnames";
import { StylistTextArea } from "../StylistTextArea";
import { AppButton } from "../../../shared/components/AppButton";
import type { SelectChangeEvent } from "@mui/material";
import { CopyButton } from "../../../shared/components/Buttons/CopyButton";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import {
  selectAssistants,
  selectSelectedAssistant,
  selectStylistOriginalText,
} from "../redux/selectors";
import { getStylistAssistantsAction } from "../redux/actions";
import { AssistantSelection } from "./components/AssistantSelection";
import {
  setSelectedAssistantAction,
  setStylistOriginalText,
  setStylistTyping,
} from "../redux/slice";
import type { AssistantInfo } from "../../../services/api/methodsTypes";

interface Props {
  onClick: () => void;
  isButtonDisabled: boolean;
  className?: string;
}

export const StylistMain: FC<Props> = ({
  onClick,
  isButtonDisabled,
  className,
}) => {
  const dispatch = useAppDispatch();
  const assistants = useAppSelector(selectAssistants);
  const selectedAssistant = useAppSelector(selectSelectedAssistant);
  const originalText = useAppSelector(selectStylistOriginalText);
  const [minRows, setMinRows] = useState(4);

  useEffect(() => {
    dispatch(getStylistAssistantsAction());
    const calculateMinRows = () => {
      const windowHeight = window.innerHeight;
      const rowHeight = 32;
      const rows = Math.floor(windowHeight / rowHeight);
      setMinRows(rows);
    };

    calculateMinRows();

    window.addEventListener("resize", calculateMinRows);

    return () => {
      window.removeEventListener("resize", calculateMinRows);
    };
  }, []);

  const handleSelectAssistant = (event: SelectChangeEvent<unknown>) => {
    const chosenAssistant = assistants.find(
      (assistant) => assistant.name === event.target.value
    );
    dispatch(setSelectedAssistantAction(chosenAssistant as AssistantInfo));
  };

  const handleChangeOriginalText = (event: ChangeEvent<HTMLInputElement>) => {
    const userInstruction = event.target.value;
    dispatch(setStylistOriginalText(userInstruction));
    dispatch(setStylistTyping(true));
  };

  return (
    <div className={classNames(styles.main, className)}>
      <AppUnderlinedTitle className={styles.title} leftContent={"Ваш текст"} />
      <div className={styles["select-block-container"]}>
        <AssistantSelection
          assistants={assistants}
          label={"Модель стилизации"}
          value={selectedAssistant?.name || ""}
          itemText={selectedAssistant?.name as string}
          onChange={handleSelectAssistant}
        />
      </div>
      <div className={styles["text-area-container"]}>
        <StylistTextArea
          value={originalText}
          onChange={handleChangeOriginalText}
          placeholder={"Исходное письмо"}
          minRows={minRows}
        />
        <CopyButton
          onClick={() => navigator.clipboard.writeText(originalText)}
          position="absolute"
        />
      </div>
      <AppButton
        buttonType="contained"
        disabled={isButtonDisabled}
        className={styles["save-button"]}
        onClick={onClick}
      >
        Стилизовать
      </AppButton>
    </div>
  );
};
