import { useEffect, useState } from "react";
import { ResizableWrapper } from "../../shared/components/ResizableWrapper";
import styles from "./styles.module.css";
import { AppSidebar } from "../../common/Sidebar";
import { StylistMain } from "../../features/Stylisation/StylistMain";
import { StylistResult } from "../../features/Stylisation/StylistResult";
import { useAppSelector } from "../../services/redux/hooks/use-selector";
import {
  selectSelectedAssistant,
  selectStylistMode,
  selectStylistOriginalText,
  selectStylistResult,
  selectStylistTyping,
} from "../../features/Stylisation/redux/selectors";
import { useAppDispatch } from "../../services/redux/hooks/use-dispatch";
import {
  createStylistThreadAction,
  postStylistMessageAction,
} from "../../features/Stylisation/redux/actions";
import type {
  AssistantInfo,
  ThreadInfo,
  UserInfo,
} from "../../services/api/methodsTypes";
import { getPostMessage, postStylistMessage } from "./helpers";
import { setStylistTyping } from "../../features/Stylisation/redux/slice";
import { useProfile } from "../../services/profile";

export const StylistPage = () => {
  const dispatch = useAppDispatch();
  const stylistMode = useAppSelector(selectStylistMode);
  const selectedAssistant = useAppSelector(selectSelectedAssistant);
  const originalText = useAppSelector(selectStylistOriginalText);
  const result = useAppSelector(selectStylistResult);
  const typing = useAppSelector(selectStylistTyping);
  const { userInfo } = useProfile();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (typing && stylistMode === "auto") {
      const timer = setTimeout(() => {
        setIsLoading(true);
        dispatch(setStylistTyping(false));
        dispatch(createStylistThreadAction()).then((res) => {
          dispatch(postStylistMessageAction(getPostMessage(originalText)))
            .unwrap()
            .then(() => {
              postStylistMessage(
                result,
                selectedAssistant as AssistantInfo,
                (res.payload as ThreadInfo).id,
                dispatch,
                setIsLoading,
                userInfo as UserInfo
              );
            })
            .catch(console.error);
        });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [typing, originalText]);

  const handleClickStylist = () => {
    setIsLoading(true);
    dispatch(createStylistThreadAction()).then((res) => {
      dispatch(postStylistMessageAction(getPostMessage(originalText)))
        .unwrap()
        .then(() => {
          postStylistMessage(
            result,
            selectedAssistant as AssistantInfo,
            (res.payload as ThreadInfo).id,
            dispatch,
            setIsLoading,
            userInfo as UserInfo
          );
        })
        .catch(console.error);
    });
  };

  return (
    <div className={styles["page-layout"]}>
      <AppSidebar
        menuItems={[
          {
            id: "1",
            name: "Default",
          },
        ]}
        selectedId={"1"}
        onSelect={() => {}}
        hasHeader={false}
      />
      <ResizableWrapper
        leftBlock={
          <StylistMain
            onClick={handleClickStylist}
            isButtonDisabled={isLoading || stylistMode === "auto"}
            className={styles.wrapper}
          />
        }
        rightBlock={
          <StylistResult
            isResultsDisabled={isLoading}
            className={styles.wrapper}
            isLoading={isLoading}
          />
        }
        isCollapsible={false}
        leftBlockSize={50}
        leftBlockMinSize={35}
        rightBlockSize={50}
        rightBlockMinSize={35}
        rightClassName={styles["right-block"]}
      />
    </div>
  );
};
