import styles from "./styles.module.css";
import { AppSidebar } from "../../common/Sidebar";
import { useClassifiersPage } from "./hooks/use-classifiers-page";
import { Outlet } from "react-router-dom";

export const ClassificationPage = () => {
  const {
    classifiers,
    selectedClassifier,
    isClassifierLoading,
    addClassifier,
    copyObject,
    handleDeletedClassifier,
    handleSelectClassifier,
  } = useClassifiersPage();

  return (
    <div className={styles["page-layout"]}>
      <AppSidebar
        isObjectsStorage
        defaultName="Классификатор"
        menuItems={classifiers}
        selectedId={selectedClassifier?.id}
        onSelect={handleSelectClassifier}
        onAdd={addClassifier}
        onDelete={handleDeletedClassifier}
        handleCopyObject={copyObject}
        isLoading={isClassifierLoading}
      />
      {selectedClassifier && <Outlet />}
    </div>
  );
};
