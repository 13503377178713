import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../services/redux/types";

export const selectStylistState = (state: RootState) => state.stylistState;

export const selectAssistants = createSelector(selectStylistState, (state) => {
  return state.assistants;
});

export const selectStylistThread = createSelector(
  selectStylistState,
  (state) => {
    return state.thread;
  }
);

export const selectSelectedAssistant = createSelector(
  selectStylistState,
  (state) => {
    return state.selectedAssistant;
  }
);

export const selectStylistMode = createSelector(selectStylistState, (state) => {
  return state.stylistMode;
});

export const selectStylistOriginalText = createSelector(
  selectStylistState,
  (state) => {
    return state.originalText;
  }
);

export const selectStylistResult = createSelector(
  selectStylistState,
  (state) => {
    return state.result;
  }
);

export const selectStylistDateUpdate = createSelector(
  selectStylistState,
  (state) => {
    return state.dateUpdate;
  }
);

export const selectStylistMessages = createSelector(
  selectStylistState,
  (state) => {
    return state.messages;
  }
);

export const selectStylistTyping = createSelector(
  selectStylistState,
  (state) => {
    return state.isTyping;
  }
);
