import type { FC } from "react";
import styles from "./styles.module.css";
import type { CircularProgressProps } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { getNumber } from "../../helpers";

interface Props extends CircularProgressProps {
  progress: number;
  fileName: string;
}

export const TranscriptionProgressBar: FC<Props> = ({
  progress,
  fileName,
  ...props
}) => {
  return (
    <div className={styles["progress-bar"]}>
      <div className={styles["progress-bar-border"]}>
        <CircularProgress
          variant={"determinate"}
          value={progress}
          className={styles.loading}
          thickness={2}
          size={"20rem"}
          {...props}
        />
      </div>
      <div className={styles["progress-bar-content"]}>
        <div className={styles["progress-bar-text"]}>{progress}%</div>
        <div className={styles["progress-bar-filename"]}>{fileName}</div>
      </div>
    </div>
  );
};
