import type { AxiosError } from "axios";
import { appStorageApi } from "./instance";
import type { PostStorageObject, StorageObject, StorageObjects } from "./types";
import { showError } from "../../../../shared/utils/showError/showError";

export const getAllStorageObjects = (
  service: string
): Promise<StorageObjects> => {
  return appStorageApi
    .get(`/service/${service}?view=hierarchy`)
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("getAllObjects error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const createStorageObject = (
  postObj: PostStorageObject,
  service: string
): Promise<StorageObject> => {
  return appStorageApi
    .post(`/service/${service}`, postObj)
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("createStorageObject error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const updateStorageObject = (
  id: string,
  postObj: PostStorageObject,
  service: string
): Promise<StorageObject> => {
  return appStorageApi
    .post(`/service/${service}/${id}`, postObj)
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("updateStorageObject error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const deleteStorageObject = (
  id: string,
  service: string
): Promise<StorageObject> => {
  return appStorageApi
    .delete(`/service/${service}/${id}`)
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("deleteStorageObject error: " + error.message);
      console.log(error);
      throw error;
    });
};
