import type { SelectProps } from "@mui/material";
import { AppLabel } from "../AppLabel";
import styles from "./styles.module.css";
import type { FC, ReactNode } from "react";
import { AppSelect } from "../AppSelect";
import classNames from "classnames";

type Props = SelectProps & {
  options: ReactNode;
  label?: string;
  children?: ReactNode | undefined;
  itemText?: string;
  hasSettings?: boolean;
  className?: string;
};

export const SelectBlock: FC<Props> = ({
  options,
  children,
  itemText,
  hasSettings,
  label,
  className,
  ...props
}) => {
  return (
    <div className={classNames(styles["block-container"], className)}>
      {label && <AppLabel className={styles.label}>{label}</AppLabel>}
      <div className={styles["select-container"]}>
        <AppSelect
          itemText={itemText}
          className={styles.select}
          placeholderText="Выбрать"
          {...props}
        >
          {options}
        </AppSelect>
        {hasSettings && <div className={styles.settings}>{children}</div>}
      </div>
    </div>
  );
};
