import { useAppSelector } from "../../../../services/redux/hooks/use-selector";
import { AppSwitch } from "../../../../shared/components/AppSwitch";
import {
  selectSelectedAssistant,
  selectSwitchedDialogs,
} from "../../redux/selectors";
import styles from "./styles.module.css";
import { useAppDispatch } from "../../../../services/redux/hooks/use-dispatch";
import { saveUpdatedAssistantAction } from "../../redux/actions";
import type { AssistantInfo } from "../../../../services/api/methodsTypes";
import { updateSelectedAssistantAction } from "../../redux/slice";

export const DialoguesSwitcher = () => {
  const dispatch = useAppDispatch();
  const selectedAssistant = useAppSelector(selectSelectedAssistant);
  const isShowDialogs = useAppSelector(selectSwitchedDialogs);

  const handleDialoguesSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedAssistant = {
      ...selectedAssistant,
      metadata: {
        ...selectedAssistant?.metadata,
        dialogs: String(event.target.checked),
      },
    };
    dispatch(updateSelectedAssistantAction(updatedAssistant as AssistantInfo));
    dispatch(saveUpdatedAssistantAction(updatedAssistant as AssistantInfo));
  };

  return (
    <div className={styles["flex-container"]}>
      <AppSwitch checked={isShowDialogs} onChange={handleDialoguesSwitch} />
      Ассистент доступен в диалогах
    </div>
  );
};
