export const getNumber = (value: string) =>
  Number(value.slice(0, value.length - 1));

export const getProcessingTime = (size: number, time: string) => {
  const estimate_duration = Number(time);
  const mb = size / Math.pow(1024, 2);
  const processingTime = Math.trunc(mb * 30);

  if (estimate_duration > processingTime) {
    const formattedTime = new Date(estimate_duration * 1000);
    const minutes = formattedTime.getMinutes();
    const seconds = formattedTime.getSeconds();
    return `${Math.trunc(minutes)} мин. ${seconds} сек.`;
  } else {
    const formattedTime = new Date(processingTime * 1000);
    const minutes = formattedTime.getMinutes();
    const seconds = formattedTime.getSeconds();
    return `${Math.trunc(minutes)} мин. ${seconds} сек.`;
  }
};
