import type { PayloadAction } from "@reduxjs/toolkit";
import { setStylistResult } from "../../../features/Stylisation/redux/slice";
import {
  createRun,
  getMessages,
  getRunStatus,
  runAssistantFunction,
  submitAssistantToolOutputs,
} from "../../../services/api/methods";
import type {
  AssistantFunctionRunOutput,
  AssistantFunctionRunPostObjType,
  AssistantFunctionToolOutput,
  AssistantInfo,
  RunInfo,
  UserInfo,
} from "../../../services/api/methodsTypes";

export const getPostMessage = (message: string) => {
  const postMessage = {
    role: "user",
    content: [
      {
        type: "text",
        text: message,
      },
    ],
  } as any;
  return postMessage;
};

const checkRunStatus = (
  run: RunInfo,
  threadId: string,
  assistant: AssistantInfo,
  userInfo: UserInfo,
  dispatch: (action: PayloadAction<any>) => void,
  isLoading: (isLoading: boolean) => void
) => {
  const timer = setTimeout(function tick() {
    getRunStatus(threadId, run.id).then((runStatus) => {
      switch (runStatus.status) {
        case "in_progress":
          setTimeout(tick, 1000);
          return;
        case "queued":
          setTimeout(tick, 1000);
          return;
        case "requires_action":
          getRunStatus(threadId, run.id).then((runInfo) => {
            updateRunWithAssistantFunctions(
              runInfo,
              threadId,
              assistant,
              userInfo,
              dispatch,
              isLoading
            );
          });
          return;
        case "completed":
          getMessages(threadId).then((messages) => {
            const systemMessage =
              messages.data[messages.data.length - 1].content[0].text.value;
            dispatch(setStylistResult(systemMessage));
          });
          clearTimeout(timer);
          isLoading(false);
          return;
        default:
          return;
      }
    });
  });
};

export const postStylistMessage = (
  result: string,
  assistant: AssistantInfo,
  threadId: string,
  dispatch: (action: PayloadAction<any>) => void,
  isLoading: (isLoading: boolean) => void,
  userInfo: UserInfo
) => {
  const instruction = assistant.instructions.concat(result);
  /*     result !== ""
      ? assistant?.instructions.replace("{$result}", result)
      : assistant?.instructions; */
  const runObj = {
    assistant_id: assistant?.id!,
    instructions: instruction,
  };

  createRun(threadId, runObj)
    .then((run) => {
      checkRunStatus(run, threadId, assistant, userInfo, dispatch, isLoading);
    })
    .catch((error) => {
      console.error(error);
    });
};

const updateRunWithAssistantFunctions = (
  run: RunInfo,
  threadId: string,
  assistant: AssistantInfo,
  userInfo: UserInfo,
  dispatch: (action: PayloadAction<any>) => void,
  isLoading: (isLoading: boolean) => void
) => {
  const promises: Promise<{
    output: AssistantFunctionRunOutput;
    toolId: string;
  }>[] = [];
  const assistantMessageId = assistant.id;
  const messageThreadId = threadId;

  run.required_action.submit_tool_outputs.tool_calls.forEach((tool) => {
    const postObject: AssistantFunctionRunPostObjType = {
      params: JSON.parse(tool.function.arguments),
      credentials: {
        openai_key: null,
        base_url: userInfo?.thread_url || "",
        asst_id: assistantMessageId!,
        thread_id: messageThreadId!,
      },
    };

    promises.push(
      runAssistantFunction(tool.function.name, postObject).then((res) => ({
        output: res,
        toolId: tool.id,
      }))
    );
  });

  Promise.all(promises).then((res) => {
    const payloads: AssistantFunctionToolOutput[] = [];
    res.forEach(({ output, toolId }) => {
      const functionOutput = output.function_output;
      const payload = {
        tool_call_id: toolId,
        output: functionOutput ?? "Не найдено, попробовать позже",
      };
      payloads.push(payload);
    });

    const postObj = { tool_outputs: payloads };
    submitAssistantToolOutputs(messageThreadId!, run.id, postObj).then(
      (response) => {
        checkRunStatus(
          response,
          messageThreadId!,
          assistant,
          userInfo,
          dispatch,
          isLoading
        );
      }
    );
  });
};
