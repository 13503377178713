import type { FC } from "react";
import { SelectBlock } from "../../../../../shared/components/SelectBlock";
import { SettingsSwitch } from "../../../../../shared/components/SelectBlock/SettingsSwitch";
import type { AssistantInfo } from "../../../../../services/api/methodsTypes";
import type { SelectProps } from "@mui/material";
import { useAppDispatch } from "../../../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../../../services/redux/hooks/use-selector";
import { selectStylistMode } from "../../../redux/selectors";
import { setStylistMode } from "../../../redux/slice";
import { AppSelectOption } from "../../../../../shared/components/AppSelect/AppSelectOption";

type Props = SelectProps & {
  label: string;
  itemText: string;
  assistants: AssistantInfo[];
};

export const AssistantSelection: FC<Props> = ({
  label,
  itemText,
  assistants,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const stylistMode = useAppSelector(selectStylistMode);

  const handleSwitch = () => {
    return stylistMode === "вручную"
      ? dispatch(setStylistMode("auto"))
      : dispatch(setStylistMode("вручную"));
  };

  return (
    <SelectBlock
      hasSettings={true}
      options={assistants.map((assistant) => (
        <AppSelectOption key={assistant.id} value={assistant.name}>
          {assistant.name}
        </AppSelectOption>
      ))}
      itemText={itemText}
      label={label}
      placeholder="Выбрать"
      {...props}
    >
      <SettingsSwitch
        pickedValue={stylistMode}
        switches={[
          { id: 1, switchName: "AUTO", switchValue: "auto" },
          { id: 2, switchName: "Вручную", switchValue: "вручную" },
        ]}
        onClick={handleSwitch}
      />
    </SelectBlock>
  );
};
