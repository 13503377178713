import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { DialogsState } from "./types";
import {
  createDialogReducerBuilder,
  deleteDialogReducerBuilder,
  getAllDialogsReducerBuilder,
  getDialogAssistantsReducerBuilder,
  getDialogFunctionsReducerBuilder,
  getDialogMessagesReducerBuilder,
  postDialogMessageReducerBuilder,
  saveUpdatedAssistantReducerBuilder,
  updateMessagesReducerBuilder,
} from "./reducers";
import type {
  AssistantInfo,
  Message,
} from "../../../services/api/methodsTypes";
import type { StorageObject } from "../../../services/api/instances/storageApi/types";

const initialState: DialogsState = {
  assistants: [],
  selectedAssistant: null,
  selectedDialog: null,
  dialogName: "",
  messages: [],
  functions: [],
  dialogs: [],
  isFunctionsLoading: false,
  isMessageLoading: false,
  isDialogsLoading: false,
  isSendDisabled: false,
};

const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    setSelectedAssistantAction: (
      state,
      action: PayloadAction<AssistantInfo | null>
    ) => {
      state.selectedAssistant = action.payload;
    },

    setDialogAssistantsAction: (
      state,
      action: PayloadAction<AssistantInfo[]>
    ) => {
      state.assistants = action.payload;
    },

    setSelectedDialogAction: (
      state,
      action: PayloadAction<StorageObject | null>
    ) => {
      state.selectedDialog = action.payload;
    },
    setDialogsAction: (state, action: PayloadAction<StorageObject[]>) => {
      state.dialogs = action.payload;
    },

    addMessageAction: (state, action: PayloadAction<Message>) => {
      state.messages = [...state.messages, action.payload];
    },
    clearMessagesAction: (state) => {
      state.messages = [];
    },

    setIsSendDisabledAction: (state, action: PayloadAction<boolean>) => {
      state.isSendDisabled = action.payload;
    },
    setIsMessageLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isMessageLoading = action.payload;
    },
    updateSelectedAssistantAction: (
      state,
      action: PayloadAction<AssistantInfo>
    ) => {
      state.assistants = state.assistants.map((assistant: AssistantInfo) => {
        if (action.payload.id === assistant.id) {
          return action.payload;
        } else {
          return assistant;
        }
      });
      state.selectedAssistant = action.payload;
    },

    setDialogNameAction: (state, action: PayloadAction<string>) => {
      state.dialogName = action.payload;
    },

    setIsDialogsLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isDialogsLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    getDialogAssistantsReducerBuilder(builder);
    postDialogMessageReducerBuilder(builder);
    getDialogMessagesReducerBuilder(builder);
    getDialogFunctionsReducerBuilder(builder);
    updateMessagesReducerBuilder(builder);
    saveUpdatedAssistantReducerBuilder(builder);
    getAllDialogsReducerBuilder(builder);
    deleteDialogReducerBuilder(builder);
    createDialogReducerBuilder(builder);
  },
});

export const dialogsReducer = dialogsSlice.reducer;
export const {
  setDialogAssistantsAction,
  setSelectedAssistantAction,
  updateSelectedAssistantAction,
  setDialogsAction,
  setSelectedDialogAction,
  addMessageAction,
  clearMessagesAction,
  setIsSendDisabledAction,
  setIsMessageLoadingAction,
  setDialogNameAction,
  setIsDialogsLoadingAction,
} = dialogsSlice.actions;
