import { combineReducers } from "@reduxjs/toolkit";
import { assistantsReducer } from "../../../features/Assistants/redux/slice";
import { stylistReducer } from "../../../features/Stylisation/redux/slice";
import { audioTranscriptionReducer } from "../../../features/Modals/AudioTranscription/redux/slice";
import { dialogsReducer } from "../../../features/Dialog/redux/slice";
import { classifierReducer } from "../../../features/Classification/redux/slice";

export const reducer = combineReducers({
  assistantsState: assistantsReducer,
  stylistState: stylistReducer,
  audioTranscriptionState: audioTranscriptionReducer,
  dialogsState: dialogsReducer,
  classifierState: classifierReducer,
});
