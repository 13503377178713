import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { AudioTranscriptionState } from "./types";
import {
  getAudioTranscriptionActionReducerBuilder,
  postAudioFileActionReducerBuilder,
} from "./reducers";
import type { AudioTranscriptionInfo, TranscriptionModeType } from "../types";

const initialState: AudioTranscriptionState = {
  transcriptionMode: "upload",
  audioFileName: "",
  audioTranscriptionInfo: null,
  isFileUpload: true,
  isFileTranscription: false,
};

const audioTranscriptionSlice = createSlice({
  name: "audioTranscription",
  initialState,
  reducers: {
    setTranscriptionModeAction: (
      state,
      action: PayloadAction<TranscriptionModeType>
    ) => {
      state.transcriptionMode = action.payload;
    },

    setAudioTranscriptionInfo: (
      state,
      action: PayloadAction<AudioTranscriptionInfo>
    ) => {
      state.audioTranscriptionInfo = action.payload;
    },

    setAudioFileAction: (state, action: PayloadAction<string>) => {
      state.audioFileName = action.payload;
    },

    setIsFileUploadAction: (state, action: PayloadAction<boolean>) => {
      state.isFileUpload = action.payload;
    },

    setIsFileTranscriptionAction: (state, action: PayloadAction<boolean>) => {
      state.isFileTranscription = action.payload;
    },

    clearAudioTranscriptionState: () => initialState,
  },
  extraReducers: (builder) => {
    postAudioFileActionReducerBuilder(builder);
    getAudioTranscriptionActionReducerBuilder(builder);
  },
});

export const audioTranscriptionReducer = audioTranscriptionSlice.reducer;
export const {
  setTranscriptionModeAction,
  setAudioTranscriptionInfo,
  setAudioFileAction,
  setIsFileUploadAction,
  setIsFileTranscriptionAction,
} = audioTranscriptionSlice.actions;
