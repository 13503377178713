import { type ChangeEvent, type FC, useEffect, useState } from "react";
import { AppButton } from "../../../shared/components/AppButton";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import styles from "./styles.module.css";
import classNames from "classnames";
import { StylistTextArea } from "../StylistTextArea";
import { AppLoading } from "../../../shared/components/AppLoading";
import { CopyButton } from "../../../shared/components/Buttons/CopyButton";
import { ClearIcon } from "../../../shared/icons/ClearIcon";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import {
  selectStylistDateUpdate,
  selectStylistResult,
} from "../redux/selectors";
import { setStylistDateUpdate, setStylistResult } from "../redux/slice";

interface Props {
  isResultsDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
}

export const StylistResult: FC<Props> = ({
  isResultsDisabled,
  isLoading,
  className,
}) => {
  const dispatch = useAppDispatch();
  const result = useAppSelector(selectStylistResult);
  const dateUpdate = useAppSelector(selectStylistDateUpdate);
  const [minRows, setMinRows] = useState(4);

  useEffect(() => {
    const calculateMinRows = () => {
      const windowHeight = window.innerHeight;
      const rowHeight = 32;
      const rows = Math.floor(windowHeight / rowHeight + 4);
      setMinRows(rows);
    };

    calculateMinRows();

    window.addEventListener("resize", calculateMinRows);

    return () => {
      window.removeEventListener("resize", calculateMinRows);
    };
  }, []);

  const handleChangeResult = (event: ChangeEvent<HTMLInputElement>) => {
    const systemResponse = event.target.value;
    const date = new Date()
      .toLocaleDateString("ru-RU", {
        hour: "numeric",
        minute: "numeric",
      })
      .replace(",", "");
    dispatch(setStylistResult(systemResponse));
    dispatch(setStylistDateUpdate(date));
  };

  const handleClean = () => {
    dispatch(setStylistResult(""));
  };

  return (
    <div className={classNames(styles.result, className)}>
      <AppUnderlinedTitle className={styles.title} leftContent={"Результат"}>
        <AppButton
          buttonType={"text"}
          onClick={handleClean}
          className={styles["button-clean"]}
          icon={<ClearIcon />}
        >
          Очистить
        </AppButton>
      </AppUnderlinedTitle>
      <div className={styles["text-area-container"]}>
        <StylistTextArea
          value={result}
          onChange={handleChangeResult}
          placeholder={"Результат"}
          minRows={minRows}
          disabled={isResultsDisabled}
        />
        <CopyButton
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(result);
          }}
          position="absolute"
        />
        {isLoading && <AppLoading className={styles.loading} big />}
      </div>
      <div className={styles.date}>{dateUpdate}</div>
    </div>
  );
};
