import styles from "./styles.module.css";
import { useAppDispatch } from "../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../services/redux/hooks/use-selector";
import {
  selectDialogs,
  selectIsSendDisabled,
  selectMessages,
  selectSelectedDialog,
} from "./redux/selectors";
import type { AssistantInfo, Message } from "../../services/api/methodsTypes";
import {
  addMessageAction,
  clearMessagesAction,
  setDialogsAction,
  setIsMessageLoadingAction,
  setIsSendDisabledAction,
  setSelectedAssistantAction,
  setSelectedDialogAction,
} from "./redux/slice";
import {
  createThreadAction,
  getDialogFunctionsAction,
  postDialogMessageAction,
  updateDialogAction,
  updateMessagesAction,
} from "./redux/actions";
import type { SelectChangeEvent } from "@mui/material";
import { DialogsHeader } from "./components/DialogsHeader";
import { createRun } from "../../services/api/methods";
import { Chat } from "../../common/Chat";
import { useChat } from "../../common/hooks/useChat";
import { getFilteredAssistants, getThreadId } from "./helpers";
import { useDialogs } from "./hooks/use-dialogs";
import type { StorageObject } from "../../services/api/instances/storageApi/types";

export const Dialog = () => {
  const dispatch = useAppDispatch();
  const {
    message,
    customLoadingMessage,
    attachedFile,
    isFileLoading,
    checkStatus,
    setMessage,
    setAttachedFile,
    handleFileUpload,
    handleSendMessage,
  } = useChat();
  const { assistants, selectedAssistant, dialogFunction } = useDialogs();
  const dialogs = useAppSelector(selectDialogs);
  const selectedDialog = useAppSelector(selectSelectedDialog);
  const messages = useAppSelector(selectMessages);
  const isSendDisabled = useAppSelector(selectIsSendDisabled);

  const isSendDisabledActionFalse = () => {
    dispatch(setIsSendDisabledAction(false));
  };

  const isSendDisabledTrue = () => {
    dispatch(setIsSendDisabledAction(true));
  };

  const updatedMessages = () => {
    dispatch(updateMessagesAction())
      .unwrap()
      .finally(() => {
        dispatch(setIsSendDisabledAction(false));
      });
  };

  const addMessage = (messageObj: Message) => {
    dispatch(addMessageAction(messageObj));
  };

  const postMessage = (messageObj: Message) => {
    dispatch(postDialogMessageAction(messageObj))
      .unwrap()
      .then(() => {
        const runObj = {
          assistant_id: selectedAssistant?.id!,
        };
        const threadId = getThreadId(selectedDialog as StorageObject);
        createRun(threadId, runObj)
          .then((run) => {
            checkStatus(
              threadId,
              run,
              updatedMessages,
              isSendDisabledActionFalse,
              dialogFunction,
              selectedAssistant as AssistantInfo
            );
          })
          .catch((error) => {
            dispatch(setIsSendDisabledAction(false));
            dispatch(setIsMessageLoadingAction(false));
            console.error(error);
          });
      })
      .catch((error) => {
        dispatch(setIsSendDisabledAction(false));
        dispatch(setIsMessageLoadingAction(false));
        console.error(error);
      });
  };

  const handleSelectAssistant = (event: SelectChangeEvent<unknown>) => {
    const chosenAssistant = assistants.find(
      (assistant: AssistantInfo) => assistant.name === event.target.value
    );
    console.log("dialogFunctions 1", chosenAssistant);
    dispatch(setSelectedAssistantAction(chosenAssistant as AssistantInfo));
    dispatch(getDialogFunctionsAction(chosenAssistant?.id as string));
    console.log("dialogFunctions 2", dialogFunction);
  };

  const handleClearChat = () => {
    setMessage("");
    dispatch(clearMessagesAction());
    dispatch(createThreadAction())
      .unwrap()
      .then((res) => {
        const updatedDialog = {
          friendly_name: selectedDialog?.friendly_name as string,
          value: JSON.stringify({
            thread_id: res.id,
          }),
        };
        const id = String(selectedDialog?.id);
        const updatedDialogList = dialogs.map((dialog: StorageObject) => {
          if (String(dialog.id) === String(selectedDialog?.id)) {
            return {
              ...dialog,
              value: updatedDialog.value,
            };
          }
          return dialog;
        });
        const chosenDialog = updatedDialogList.find(
          (dialog) => dialog.id === selectedDialog?.id
        );
        dispatch(updateDialogAction({ id, postObj: updatedDialog }));
        dispatch(setDialogsAction(updatedDialogList));
        dispatch(setSelectedDialogAction(chosenDialog as StorageObject));
      });
  };

  const handleSend = () => {
    handleSendMessage(isSendDisabledTrue, addMessage, postMessage);
  };

  return (
    <div className={styles.container}>
      <Chat
        additionalContent={
          <DialogsHeader
            selectedAssistant={selectedAssistant as AssistantInfo}
            assistants={getFilteredAssistants(assistants)}
            handleSelectAssistant={handleSelectAssistant}
          />
        }
        handleClearChat={handleClearChat}
        className={styles.chat}
        userMessage={message}
        setUserMessage={setMessage}
        handleSend={handleSend}
        handleFileUpload={handleFileUpload}
        isDisabled={isSendDisabled}
        messages={messages}
        attachedFile={attachedFile}
        setAttachedFile={setAttachedFile}
        isFileLoading={isFileLoading}
        isMessageLoading={isSendDisabled}
        customLoadingMessage={customLoadingMessage}
        hasViewAssistantName
      />
    </div>
  );
};
