export const appRouterPaths = {
  embeddings: "/embedding_models",
  comparasion: "/llm_compare",
  classifiers: "/classification_model_education",
  classifier: "/classification_model_education/:id",
  stylization: "/stylizator",
  assistants: "/assitant",
  assistant: "/assitant/:id",
  dialogs: "/dialog",
  dialog: "/dialog/:id",
  database: "/knowledge_base",
  chatbot: "/telegramm_bots",
  login: "/login",
  notFound: "*",
};

export const getAssistantPagePath = (id: string) => {
  return appRouterPaths.assistant.replace(":id", id);
};

export const getDialogPagePath = (id: string) => {
  return appRouterPaths.dialog.replace(":id", id);
};

export const getClassifierPagePath = (id: string) => {
  return appRouterPaths.classifier.replace(":id", id);
};
