import { type ChangeEvent, useCallback } from "react";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import { updateStorageObjectAction } from "../redux/actions";
import {
  selectClassifiers,
  selectClassifierData,
  selectSelectedClassifier,
} from "../redux/selectors";
import { debounce } from "throttle-debounce";
import {
  setClassifierDataAction,
  setClassifierNameAction,
  setClassifiersAction,
  setSelectedClassifierAction,
} from "../redux/slice";
import type {
  PostStorageObject,
  StorageObject,
} from "../../../services/api/instances/storageApi/types";
import type { ClassPostObjType } from "../../../services/api/instances/classificatorApi/types";

export const useClassifier = () => {
  const dispatch = useAppDispatch();
  const classifiers = useAppSelector(selectClassifiers);
  const classifierData = useAppSelector(selectClassifierData);
  const selectedClassifier = useAppSelector(selectSelectedClassifier);

  const updateClassifier = useCallback(
    debounce(300, (id: string, postObj: PostStorageObject) => {
      dispatch(updateStorageObjectAction({ id, postObj }));
    }),
    []
  );

  const handleInputFieldChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: keyof ClassPostObjType
  ) => {
    const updatedClassifierData = {
      ...classifierData,
      [field]: event.target.value,
    };
    const classifierId = String(selectedClassifier?.id);
    const updatedClassifiers = classifiers.map((classifier: StorageObject) => {
      if (String(classifier.id) === classifierId) {
        return {
          ...classifier,
          value: JSON.stringify({
            classifier_object: updatedClassifierData,
          }),
        };
      }
      return classifier;
    });
    dispatch(
      setClassifierDataAction(updatedClassifierData as ClassPostObjType)
    );
    dispatch(setClassifiersAction(updatedClassifiers));
    updateClassifier(classifierId, {
      friendly_name: selectedClassifier?.friendly_name as string,
      value: JSON.stringify({
        classifier_object: updatedClassifierData,
      }),
    });
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedClassifier = {
      friendly_name: event.target.value,
      value: selectedClassifier?.value,
    };
    const id = String(selectedClassifier?.id);
    const updatedClassifiersList = classifiers.map(
      (classifier: StorageObject) => {
        if (String(classifier.id) === String(selectedClassifier?.id)) {
          return {
            ...classifier,
            friendly_name: event.target.value,
          };
        }
        return classifier;
      }
    );
    updateClassifier(id, updatedClassifier as PostStorageObject);
    dispatch(setClassifiersAction(updatedClassifiersList));
    dispatch(setClassifierNameAction(event.target.value));
    dispatch(
      setSelectedClassifierAction({
        ...selectedClassifier,
        friendly_name: event.target.value,
      } as StorageObject)
    );
  };

  return {
    handleInputFieldChange,
    onNameChange,
  };
};
