import type { SelectChangeEvent, SelectProps } from "@mui/material";
import type { FC } from "react";
import styles from "./styles.module.css";
import type { AssistantInfo } from "../../../../services/api/methodsTypes";
import { AppSelectOption } from "../../../../shared/components/AppSelect/AppSelectOption";
import { SelectBlock } from "../../../../shared/components/SelectBlock";

type Props = SelectProps & {
  assistants: AssistantInfo[];
  itemText: string;
  onSelectAssistant: (event: SelectChangeEvent<unknown>) => void;
  label?: string;
};

export const DialogAssistantSelection: FC<Props> = ({
  assistants,
  itemText,
  onSelectAssistant,
  ...props
}) => {
  return (
    <SelectBlock
      options={assistants?.map((assistant) => (
        <AppSelectOption key={assistant.id} value={assistant.name}>
          {assistant.name}
        </AppSelectOption>
      ))}
      onChange={onSelectAssistant}
      itemText={itemText}
      className={styles["dialog-assistant-selection"]}
      {...props}
    />
  );
};
