import { createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../../../services/redux/types";
import {
  setClassifierDataAction,
  setClassifierNameAction,
  setClassifierResultsAction,
  setClassifiersAction,
  setSelectedClassifierAction,
} from "./slice";
import {
  appRouterPaths,
  getClassifierPagePath,
} from "../../../services/navigation/urls";
import type { NavigateFunction } from "react-router-dom";
import { getCompModels } from "../../../services/api/methods";
import {
  createStorageObject,
  deleteStorageObject,
  getAllStorageObjects,
  updateStorageObject,
} from "../../../services/api/instances/storageApi/methods";
import type { PostStorageObject } from "../../../services/api/instances/storageApi/types";
import type { ClassPostObjType } from "../../../services/api/instances/classificatorApi/types";
import { getClasses } from "../../../services/api/instances/classificatorApi/methods";

export const getCompModelsAction = createAsyncThunk(
  "classifier/getClassifierModels",
  async () => {
    try {
      return await getCompModels();
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getAllClassifiersAction = createAsyncThunk(
  "classifier/getAllClassifiers",
  async () => {
    try {
      return await getAllStorageObjects("classify");
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const createClassifierAction = createAsyncThunk(
  "classifier/createClassifier",
  async (postObj: PostStorageObject) => {
    try {
      return await createStorageObject(postObj, "classify");
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const updateStorageObjectAction = createAsyncThunk(
  "classifier/updateClassifier",
  async (body: { id: string; postObj: PostStorageObject }, _thunkAPI) => {
    try {
      const { id, postObj } = body;
      return await updateStorageObject(id, postObj, "classify");
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const deleteClassifierAction = createAsyncThunk(
  "classifier/deleteClassifier",
  async (body: { id: string; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const { navigate, id } = body;
      const { classifiers, selectedClassifier } = (
        thunkAPI.getState() as RootState
      ).classifierState;
      const nextClassifier = classifiers.filter(
        (classifier) => String(classifier.id) !== String(id)
      );
      const lastClassifier = nextClassifier[nextClassifier.length - 1];

      thunkAPI.dispatch(setClassifiersAction(nextClassifier));

      if (!nextClassifier[0]) {
        navigate(appRouterPaths.classifiers);
        thunkAPI.dispatch(setSelectedClassifierAction(null));
        thunkAPI.dispatch(setClassifierNameAction(""));
        thunkAPI.dispatch(setClassifiersAction([]));
        thunkAPI.dispatch(setClassifierResultsAction(null));
        thunkAPI.dispatch(setClassifierDataAction(null));
      } else if (String(selectedClassifier?.id) === String(id)) {
        thunkAPI.dispatch(setSelectedClassifierAction(lastClassifier));
        thunkAPI.dispatch(
          setClassifierNameAction(lastClassifier.friendly_name)
        );
        thunkAPI.dispatch(setClassifierResultsAction(null));
        const lastClassifierValue: ClassPostObjType = JSON.parse(
          lastClassifier.value as string
        ).classifier_object;
        thunkAPI.dispatch(setClassifierDataAction(lastClassifierValue));

        navigate(getClassifierPagePath(String(lastClassifier.id!)));
      }

      return await deleteStorageObject(id, "classify");
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getClassesAction = createAsyncThunk(
  "classifier/getClasses",
  async (postObj: ClassPostObjType, _thunkAPI) => {
    try {
      return await getClasses(postObj);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);
