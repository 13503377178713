import type { FC } from "react";
import classNames from "classnames";
import styles from "../styles.module.css";
import { IconButton } from "@mui/material";
import { TrashIcon } from "../../../shared/icons/TrashIcon";
import type { MenuItem } from "../types";

interface Props {
  menuItems: MenuItem[];
  onSelect: (id: string) => void;
  selectedId?: string | null;
  defaultName?: string;
  onDelete?: (id: string, name: string) => void;
  isSendDisabled?: boolean;
}

export const SidebarList: FC<Props> = ({
  menuItems,
  onSelect,
  selectedId,
  defaultName,
  onDelete,
  isSendDisabled,
}) => {
  return (
    <>
      {menuItems?.map((item) => (
        <li
          key={item.id}
          className={classNames(styles["list-item"], {
            [styles.selected]: item.id === selectedId,
            [styles.arrow]: item.id === selectedId && !onDelete,
            [styles["select-bg"]]: item.id === selectedId,
          })}
        >
          <span
            className={classNames(styles["item-name"], {
              [styles.disabled]: isSendDisabled,
            })}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              !isSendDisabled && item.id && onSelect(item.id);
            }}
          >
            {!item.name ? defaultName : item.name}
          </span>
          {onDelete && (
            <IconButton
              className={classNames(styles["delete-button"], {
                [styles.disabled]: isSendDisabled,
              })}
              onClick={() => onDelete(item.id, item.name)}
            >
              <TrashIcon />
            </IconButton>
          )}
        </li>
      ))}
    </>
  );
};
