import type { AxiosError, AxiosProgressEvent } from "axios";
import { multiUserApi } from "../../../../shared/utils/multiUserFunctions";
import { showError } from "../../../../shared/utils/showError/showError";
import { appAudioTranscriptionApi } from "./instance";
import type {
  AudioTranscriptionGetResponse,
  AudioTranscriptionPostResponse,
} from "./types";
import { audioTranscriptionToken } from "./constants";

export const postAudioTranscription = (
  file: File,
  onUploadProgress?: (event: AxiosProgressEvent) => void
): Promise<AudioTranscriptionPostResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  return appAudioTranscriptionApi
    .post("/recognize", formData, {
      headers: {
        File: encodeURIComponent(file.name),
        "X-Keycloak-Token": multiUserApi.getUserKey("access_token"),
      },
      onUploadProgress,
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      showError("postAudioTranscription error: " + error.message);
      console.log(error);
      throw error;
    });
};

export const getAudioTranscription = (
  hash: string,
  signal: AbortSignal
): Promise<AudioTranscriptionGetResponse> => {
  return appAudioTranscriptionApi
    .get("/recognize/" + hash, {
      headers: {
        "X-Keycloak-Token": multiUserApi.getUserKey("access_token"),
      },
      withCredentials: false,
      signal,
    })
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        showError("Unauthorized: Access token is invalid or missing");
        console.log(error);
        throw error;
      }
      if (error.response?.status === 500) {
        showError(
          "Processing Error: An error occurred while processing the file"
        );
        console.log(error);
        throw error;
      } else {
        showError("getAudioTranscription error: " + error.message);
        console.log(error);
        throw error;
      }
    });
};
