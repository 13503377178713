import { useState } from "react";
import { ClassLeftContainer } from "./ClassLeftContainer";
import { ClassRightContainer } from "./ClassRightContainer";
import styles from "./styles.module.css";
import { useAppDispatch } from "../../services/redux/hooks/use-dispatch";
import { getClassesAction, updateStorageObjectAction } from "./redux/actions";
import { useAppSelector } from "../../services/redux/hooks/use-selector";
import {
  selectClassifierData,
  selectClassifierResults,
  selectSelectedClassifier,
} from "./redux/selectors";
import { setClassifierResultsAction } from "./redux/slice";
import type {
  ClassPostObjType,
  Label,
} from "../../services/api/instances/classificatorApi/types";
import {
  classificatorApiUrl,
  classificatorFolderId,
  classificatorKey,
  classificatorModel,
} from "../../services/api/instances/classificatorApi/constants";
import type { PostStorageObject } from "../../services/api/instances/storageApi/types";

export const getRefTextForJson = (ref?: string) => {
  if (!ref) {
    return "";
  }
  return `${ref.slice(0, 6)}*************${ref.slice(-6)}`;
};

export const Classification = () => {
  const dispatch = useAppDispatch();
  const classifierResult = useAppSelector(selectClassifierResults);
  const classifierData = useAppSelector(selectClassifierData);
  const selectedClassifier = useAppSelector(selectSelectedClassifier);
  const [isLoading, setIsLoading] = useState(false);

  const cleanLabelsAndSamples = (labels: Label[] = []): Label[] => {
    return labels.map((label) => {
      const { id, ...restLabel } = label;
      return {
        ...restLabel,
        samples: restLabel.samples.map((sample) => {
          const { id, ...restSample } = sample;

          return restSample.value as any;
        }),
      };
    });
  };

  const getRequestData = () => {
    return {
      prefix: classifierData?.prefix,
      labels: cleanLabelsAndSamples(classifierData?.labels),
      text_request: classifierData?.text_request,
      folder_id: classificatorFolderId,
      api_key: classificatorKey,
      model: classificatorModel,
    };
  };

  const classifierUpdate = (id: string, postObj: PostStorageObject) => {
    dispatch(updateStorageObjectAction({ id, postObj }));
  };

  const handleClassification = () => {
    setIsLoading(true);
    const requestData = getRequestData();

    dispatch(getClassesAction(requestData as ClassPostObjType))
      .unwrap()
      .then((response) => {
        dispatch(setClassifierResultsAction(response));
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getRequestBody = () => {
    const requestData = getRequestData();

    return `
      curl -X POST ${classificatorApiUrl}
       -H "Authorization: Bearer XXXXX"
       -H "Content-Type: application/json"
       -d '{"ref": "${getRefTextForJson(selectedClassifier?.ref)}", "text": "${
      requestData.text_request || ""
    }" }'
    `;
  };

  const getRequestBodyForCopy = () => {
    const requestData = getRequestData();

    return `
      curl -X POST ${classificatorApiUrl}
       -H "Authorization: Bearer XXXXX"
       -H "Content-Type: application/json"
       -d '{"ref": "${selectedClassifier?.ref}", "text": "${
      requestData.text_request || ""
    }" }'
    `;
  };

  return (
    <div className={styles["layout-container"]}>
      <ClassLeftContainer
        updateClassifier={classifierUpdate}
        leftData={classifierData as ClassPostObjType}
      />

      <ClassRightContainer
        data={classifierResult}
        leftData={classifierData?.labels as ClassPostObjType["labels"]}
        isLoading={isLoading}
        onClassification={handleClassification}
        requestBody={getRequestBody}
        getRequestBodyForCopy={getRequestBodyForCopy}
      />
    </div>
  );
};
