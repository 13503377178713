import classNames from "classnames";
import styles from "./styles.module.css";
import { AddButton } from "./SidebarButtons/AddButton";
import { SidebarFooter } from "./SidebarFooter";
import { AskGPTButton } from "./SidebarButtons/AskGPTButton";
import { useState } from "react";
import { AppLoading } from "../../shared/components/AppLoading";
import { AudioTranscription } from "../../features/Modals/AudioTranscription";
import { ChatModal } from "./ChatModal";
import { SoundToTextButton } from "./SidebarButtons/SoundToTextButton";
import { SidebarList } from "./SidebarList";
import type { MenuItem } from "./types";
import { SidebarObjectsList } from "./SidebarObjectsList";
import { CopyObjectButton } from "./SidebarButtons/CopyObjectButton";
import { useLocation } from "react-router-dom";
import type { StorageObject } from "../../services/api/instances/storageApi/types";

interface Props {
  menuItems: MenuItem[] | StorageObject[] | null;
  onSelect: (id: string) => void;
  defaultName?: string;
  selectedId?: string | number | null;
  onAdd?: () => void;
  onDelete?: (id: string, name: string) => void;
  handleCopyObject?: () => void;
  isSendDisabled?: boolean;
  isLoading?: boolean;
  hasHeader?: boolean;
  isObjectsStorage?: boolean;
  className?: string;
}

export const AppSidebar: React.FC<Props> = ({
  menuItems,
  defaultName,
  selectedId,
  onSelect,
  onAdd,
  onDelete,
  handleCopyObject,
  isSendDisabled,
  isLoading,
  isObjectsStorage = false,
  className,
  hasHeader = true,
}) => {
  const [askGptModalOpen, setAskGptModalOpen] = useState<boolean>(false);
  const [audioTranscriptionModalOpen, setAudioTranscriptionModalOpen] =
    useState<boolean>(false);
  const location = useLocation();

  const handleAskGPTModalOpen = () => {
    setAskGptModalOpen(true);
  };

  const handleAudioTranscriptionModalOpen = () => {
    setAudioTranscriptionModalOpen(true);
  };

  const handleAskGPTModalClose = () => {
    setAskGptModalOpen(false);
  };

  const handleAudioTranscriptionModalClose = () => {
    setAudioTranscriptionModalOpen(false);
  };

  return (
    <>
      <aside className={classNames(styles.sidebar, className)}>
        {hasHeader && (
          <div className={styles["sidebar-header"]}>
            {onAdd && (
              <div className={styles["button-container"]}>
                <AddButton onAdd={onAdd} isDisabled={isSendDisabled} />
              </div>
            )}
          </div>
        )}
        <div className={styles["sidebar-body"]}>
          <ul className={styles.list}>
            {isLoading && <AppLoading className={styles.loader} small />}
            {isObjectsStorage ? (
              <SidebarObjectsList
                menuItems={menuItems as StorageObject[]}
                onSelect={onSelect}
                selectedId={selectedId as number}
                defaultName={defaultName}
                onDelete={onDelete}
                isSendDisabled={isSendDisabled}
              />
            ) : (
              <SidebarList
                menuItems={menuItems as MenuItem[]}
                onSelect={onSelect}
                selectedId={selectedId as string}
                defaultName={defaultName}
                onDelete={onDelete}
                isSendDisabled={isSendDisabled}
              />
            )}
          </ul>
        </div>
        <SidebarFooter>
          <AskGPTButton openAskGPT={handleAskGPTModalOpen} />
          <SoundToTextButton
            openSoundToText={handleAudioTranscriptionModalOpen}
          />
        </SidebarFooter>
        {location.pathname.includes("/classification_model_education") && (
          <CopyObjectButton handleCopyObject={handleCopyObject} />
        )}
      </aside>
      <AudioTranscription
        open={audioTranscriptionModalOpen}
        onClose={handleAudioTranscriptionModalClose}
        closeModal={handleAudioTranscriptionModalClose}
      />
      <ChatModal
        open={askGptModalOpen}
        closeModal={handleAskGPTModalClose}
        onClose={handleAskGPTModalClose}
      />
    </>
  );
};
