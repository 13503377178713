import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import type { AudioTranscriptionState } from "./types";
import { getAudioTranscriptionAction, postAudioFileAction } from "./actions";

export function postAudioFileActionReducerBuilder(
  builder: ActionReducerMapBuilder<AudioTranscriptionState>
) {
  builder.addCase(postAudioFileAction.pending, (state) => {
    state.transcriptionMode = "loading";
    state.isFileUpload = true;
  });
  builder.addCase(postAudioFileAction.fulfilled, (state) => {
    state.transcriptionMode = "ready";
    state.isFileUpload = false;
  });
  builder.addCase(postAudioFileAction.rejected, (state) => {
    state.transcriptionMode = "failed";
    state.isFileUpload = false;
  });
}

export function getAudioTranscriptionActionReducerBuilder(
  builder: ActionReducerMapBuilder<AudioTranscriptionState>
) {
  builder.addCase(getAudioTranscriptionAction.pending, (state) => {
    state.isFileTranscription = true;
  });
  builder.addCase(getAudioTranscriptionAction.fulfilled, (state) => {
    state.transcriptionMode = "processing";
    state.isFileTranscription = true;
  });
  builder.addCase(getAudioTranscriptionAction.rejected, (state) => {
    state.transcriptionMode = "failed";
    state.isFileTranscription = false;
  });
}
