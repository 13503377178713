import type {
  ThreadInfo,
  RunInfo,
  AssistantFunction,
  AssistantFunctionRunOutput,
  AssistantInfo,
  AssistantFunctionRunPostObjType,
  AssistantFunctionToolOutput,
  FilePurpose,
  UploadedFile,
  Message,
  MessageContent,
} from "../../services/api/methodsTypes";
import { showError } from "../../shared/utils/showError/showError";
import { useProfile } from "../../services/profile";
import {
  getRunStatus,
  runAssistantFunction,
  submitAssistantToolOutputs,
  uploadFile,
} from "../../services/api/methods";
import { useState } from "react";

export const useChat = () => {
  const [message, setMessage] = useState<string>("");
  const [customLoadingMessage, setCustomLoadingMessage] = useState<string>("");
  const [attachedFile, setAttachedFile] = useState<UploadedFile | null>(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const { userInfo } = useProfile();

  const checkStatus = (
    threadId: string,
    run: RunInfo,
    updateMessage: () => void,
    setIsSendDisabled: () => void,
    functions: AssistantFunction[],
    selectedAssistant: AssistantInfo
  ) => {
    const startTime = new Date(Number(run.created_at) * 1000);
    const timer = setTimeout(function tick() {
      getRunStatus(threadId, run.id).then((runInfo) => {
        switch (runInfo.status) {
          case "in_progress":
          case "queued": {
            const newDate = new Date();
            const duration = Math.floor(
              (Number(newDate) - Number(startTime)) / 1000
            );
            if (duration > 120) {
              showError(
                "Время ожидания функции превышено, попробуйте отправить сообщение снова"
              );
              clearTimeout(timer);
              setIsSendDisabled();
              return;
            } else {
              setTimeout(tick, 1000);
            }
            return;
          }
          case "requires_action":
            getRunStatus(threadId, run.id).then((status) => {
              connectFunctions(
                status,
                threadId,
                updateMessage,
                setIsSendDisabled,
                functions,
                selectedAssistant
              );
            });
            return;
          case "completed":
            updateMessage();
            clearTimeout(timer);
            return;
          case "failed":
            showError(
              "Что-то пошло не так, попробуйте отправить сообщение снова"
            );
            clearTimeout(timer);
            setIsSendDisabled();
            return;
          default:
            return;
        }
      });
    });
  };

  const connectFunctions = (
    run: RunInfo,
    threadId: string,
    updateMessage: () => void,
    setIsSendDisabled: () => void,
    functions: AssistantFunction[],
    selectedAssistant: AssistantInfo
  ) => {
    const selectedFunction = functions?.find(
      (func) =>
        func.function_name ===
        run.required_action.submit_tool_outputs.tool_calls[0].function.name
    )?.friendly_name;
    console.log("functions", selectedFunction);
    setCustomLoadingMessage(`Выполняется функция "${selectedFunction}"`);
    const promises: Promise<{
      output: AssistantFunctionRunOutput;
      toolId: string;
    }>[] = [];
    const assistantMessageId = selectedAssistant?.id;
    const messageThreadId = threadId;

    run.required_action.submit_tool_outputs.tool_calls.forEach((tool) => {
      const postObject: AssistantFunctionRunPostObjType = {
        params: JSON.parse(tool.function.arguments),
        credentials: {
          openai_key: null,
          base_url: userInfo?.thread_url || "",
          asst_id: assistantMessageId!,
          thread_id: messageThreadId!,
        },
      };
      promises.push(
        runAssistantFunction(tool.function.name, postObject).then((res) => ({
          output: res,
          toolId: tool.id,
        }))
      );
    });

    Promise.all(promises).then((res) => {
      setCustomLoadingMessage("Отправляются результаты");
      const payloads: AssistantFunctionToolOutput[] = [];
      res.forEach(({ output, toolId }) => {
        const functionOutput = output.function_output;
        const payload = {
          tool_call_id: toolId,
          output: functionOutput ?? "Не найдено, попробовать позже",
        };
        payloads.push(payload);
      });

      const postObj = { tool_outputs: payloads };
      submitAssistantToolOutputs(messageThreadId!, run.id, postObj).then(
        (response) => {
          checkStatus(
            threadId,
            response,
            updateMessage,
            setIsSendDisabled,
            functions,
            selectedAssistant
          );
          setCustomLoadingMessage("");
        }
      );
    });
  };

  const handleFileUpload = (e: any) => {
    if (!e.target.files[0]) return;
    setAttachedFile(null);
    setIsFileLoading(true);
    const postObj = {
      file: e.target.files[0],
      purpose: "assistants" as FilePurpose,
    };

    uploadFile(postObj)
      .then(setAttachedFile)
      .finally(() => setIsFileLoading(false));
  };

  const handleSendMessage = (
    setIsSendDisabled: () => void,
    addMessage: (message: Message) => void,
    postMessage: (message: Message) => void
  ) => {
    if (!message && !attachedFile) {
      return;
    }

    const messageObj = {
      role: "user",
      content: [
        {
          type: "text",
          text: message,
        },
      ],
    } as any;

    if (attachedFile) {
      const imageExtensions = [".png", ".jpg", ".jpeg", ".webp"];
      imageExtensions.forEach((ext) => {
        if (attachedFile.filename.endsWith(ext)) {
          messageObj.content = [
            {
              type: "text",
              text: message,
            },
            {
              type: "image_file",
              image_file: { file_id: attachedFile.id },
            },
          ];
        }
      });

      messageObj.attachments = [
        {
          file_id: attachedFile.id,
          tools: [{ type: "code_interpreter" }],
        },
      ];
      messageObj.metadata = {
        [attachedFile.id]: attachedFile.filename,
      };
      setAttachedFile(null);
    }
    setIsSendDisabled();
    addMessage({
      ...messageObj,
      id: Math.random().toString(),
      content: [
        ...messageObj.content.filter(
          (item: MessageContent) => item.type !== "text"
        ),
        {
          type: "text",
          text: {
            value: message,
          },
        },
      ],
    } as any);
    postMessage(messageObj);
    setMessage("");
  };

  return {
    message,
    customLoadingMessage,
    attachedFile,
    isFileLoading,
    checkStatus,
    setMessage,
    setCustomLoadingMessage,
    handleFileUpload,
    setAttachedFile,
    handleSendMessage,
  };
};
