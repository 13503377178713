import { AppInput } from "../../../../shared/components/AppInput";
import styles from "./styles.module.css";
import type { ChangeEvent } from "react";
import { useAppSelector } from "../../../../services/redux/hooks/use-selector";
import { selectSelectedClassifier } from "../../redux/selectors";
import {CopyButton} from "../../../../shared/components/Buttons/CopyButton";

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

export const ClassCommonSetting: React.FC<Props> = ({ name, onChange }) => {
  const selectedClassifier = useAppSelector(selectSelectedClassifier);

  const getRefText = () => {
    return `ID: ${selectedClassifier?.ref.slice(0, 6)}*************${selectedClassifier?.ref.slice(-6)}`;
  };

  return (
    <div className={styles["common-setting-container"]}>
      <AppInput
        value={name}
        onChange={onChange}
        className={styles.input}
        small
        placeholder="Название классификатора"
      />
      <div className={styles.idContainer}>
        <div>{getRefText()}</div>
        <CopyButton
          onClick={() => navigator.clipboard.writeText(selectedClassifier?.ref || "")}
        />
      </div>
      {/* <SelectBlock
        label={"Выбор модели"}
        options={models?.map((model) => (
          <AppSelectOption key={model.model_name} value={model.friendly_name}>
            {model.friendly_name}
          </AppSelectOption>
        ))}
        itemText={itemText}
        value={value}
        onChange={handleSelect}
      /> */}
    </div>
  );
};
